import { Button, Segment } from "semantic-ui-react";

export default ({ user, onDelete, onRemovePaymentMethod, onUnDelete }) => {
    return (
        <>
            <Segment vertical>
                <p>Remove payment method</p>
                <p>
                    <small>
                        This will remove the user's payment method from Stripe
                        and from our database.
                    </small>
                </p>
                <Button primary onClick={onRemovePaymentMethod} className="red">
                    Remove payment method
                </Button>
            </Segment>
            {user.deletedAt === undefined &&
            <Segment vertical>
                <p>Delete member</p>
                <p>
                    <small>
                        Deleted members are recoverable at a later date.
                    </small>
                </p>

                <Button primary onClick={onDelete} className="red">
                    Delete user
                </Button>
            </Segment>
            }
            {user.deletedAt !== undefined &&
            <Segment vertical>
                <p>Un-Delete member</p>
                <p>
                    <small>
                        This member will appear in normal searches and can be given memberships again.
                    </small>
                </p>

                <Button primary onClick={onUnDelete} className="green">
                    Un-Delete user
                </Button>
            </Segment>
            }
        </>
    );
};
