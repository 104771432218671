import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Segment, Button, Select, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { isBefore, isAfter, addYears, format } from "date-fns";
import GolfClub from "../../models/GolfClub";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import ValidationError from "../../assets/components/ValidationError";
import DatePicker from "../../assets/components/DatePicker";
import NumberInput from "../../assets/components/NumberInput";
import useDispatchUtilities from "../../utilities/useDispatchUtilities";
import CourseVoucher from "../../models/CourseVoucher";
import PartnerClubContract from "../../models/PartnerClubContract";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";

const Create = ({ history, user, dispatch }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [saving, setSaving] = useState(false);
    const [useContract, setUseContract] = useState(false);
    const [voucher, setVoucher] = useState(
        new CourseVoucher({
            activatesAt: new Date(),
            expiresAt: addYears(new Date(), 1),
            course: null,
            user: null,
            contract: null,
            players: 4,
        })
    );

    const [courses, setCourses] = useState([]);
    const [confirmedClubId, setConfirmedClubId] = useState(null);
    const [courseContracts, setCourseContracts] = useState([]);

    const { setNotification, setValidationErrors, removeValidationErrors } =
        useDispatchUtilities(dispatch);

    useEffect(() => {
        voucher.setStateManager(setVoucher);
    }, []);

    const store = async () => {
        setSaving(true);
        try {
            await voucher.store();
            setNotification("success", "Contract created");
            removeValidationErrors();
            history.push("/course-vouchers");
        } catch (exception) {
            setSaving(false);
            setValidationErrors(exception);
            setNotification("error", "Failed to create voucher");
        }
    };

    const fetchSelectedCourseContracts = async (courseId) => {
        const { data } = await PartnerClubContract.index({
            course: courseId,
            usable: true,
            hasAvailability: true,
        });

        setCourseContracts(data);
        const contract = data[0] ?? null;
        if (contract === null || useContract === false) {
            setUseContract(false);
            return voucher.useStateManager((model) => {
                model.activatesAt = new Date();
                model.expiresAt = addYears(new Date(), 1);
                model.contract = null;
            });
        }

        voucher.useStateManager((model) => {
            model.activatesAt = contract.activatesAt;
            model.expiresAt = contract.expiresAt;
            model.contract = contract;
            model.players = 4;
        });
    };

    const onChangeConfirmedClub = async (clubId) => {
        const courses = await new GolfClub({ _id: clubId }).coursesIndex({
            sort: "name",
        });
        setConfirmedClubId(clubId);
        setCourses(courses);
        const selectedCourse = courses[0]?._id ?? null;
        if (selectedCourse !== null) {
            fetchSelectedCourseContracts(selectedCourse);
        }
        voucher.useStateManager((model) => {
            model.course = selectedCourse;
        });
    };

    const onContractSelect = (id) => {
        if (id === null) {
            return voucher.useStateManager((model) => {
                model.activatesAt = new Date();
                model.expiresAt = addYears(new Date(), 1);
                model.contract = null;
            });
        }

        const selected = courseContracts.find(({ _id }) => _id === id);
        voucher.useStateManager((model) => {
            model.activatesAt = selected.activatesAt;
            model.expiresAt = selected.expiresAt;
            model.contract = selected;
        });
    };

    const disabledDatesActivation = (day) => {
        if (voucher.contract === null) {
            return isBefore(day, new Date());
        }

        return (
            isBefore(day, voucher.contract.activatesAt) ||
            isAfter(day, voucher.expiresAt)
        );
    };

    const disabledDatesExpiry = (day) => {
        if (voucher.contract === null) {
            return isBefore(day, voucher.activatesAt);
        }

        return (
            isBefore(day, voucher.activatesAt) ||
            isAfter(day, voucher.contract.expiresAt)
        );
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                <div className="container skinny">
                    <div className="row skinny">
                        <div className="col">
                            <h2>Create voucher</h2>
                            <Segment vertical>
                                <label className="with-input">Member</label>
                                <MemberSearchSelect
                                    value={voucher.user}
                                    onChange={(value) => {
                                        voucher.useStateManager(
                                            (model) => (model.user = value)
                                        );
                                    }}
                                />
                            </Segment>
                            {voucher.user !== null && (
                                <>
                                    <Segment vertical>
                                        <label className="with-input">
                                            Club
                                        </label>
                                        <GolfClubSearchSelect
                                            memberCanAccess={voucher.user}
                                            value={confirmedClubId}
                                            onChange={onChangeConfirmedClub}
                                        />
                                    </Segment>
                                    {confirmedClubId !== null && (
                                        <>
                                            <Segment vertical>
                                                <ValidationError field="course" />
                                                <label className="with-input">
                                                    Course
                                                </label>
                                                <Select
                                                    search
                                                    value={voucher.course}
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        voucher.useStateManager(
                                                            (model) => {
                                                                model.course =
                                                                    value;
                                                                fetchSelectedCourseContracts(
                                                                    value
                                                                );
                                                            }
                                                        );
                                                    }}
                                                    options={courses.map(
                                                        (club, index) => {
                                                            return {
                                                                key: index,
                                                                text: club.name,
                                                                value: club._id,
                                                            };
                                                        }
                                                    )}
                                                />
                                            </Segment>
                                            {courseContracts.length > 0 && (
                                                <>
                                                    <Segment vertical>
                                                        <Checkbox
                                                            toggle
                                                            label="Use contract"
                                                            checked={
                                                                useContract
                                                            }
                                                            onChange={() => {
                                                                const on =
                                                                    useContract ===
                                                                    false;
                                                                onContractSelect(
                                                                    on
                                                                        ? courseContracts[0]
                                                                              ._id
                                                                        : null
                                                                );
                                                                setUseContract(
                                                                    on
                                                                );
                                                                if (
                                                                    on === true
                                                                ) {
                                                                    voucher.useStateManager(
                                                                        (
                                                                            model
                                                                        ) => {
                                                                            model.players = 4;
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Segment>
                                                    {useContract && (
                                                        <>
                                                            <p className="mt-3">
                                                                This course has
                                                                the following
                                                                contracts with
                                                                availability:
                                                            </p>
                                                            {courseContracts.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="mt-3"
                                                                    >
                                                                        <Link
                                                                            className="mr-2"
                                                                            to={`/partner-club-contracts/${item._id}`}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                        {
                                                                            item.statusLabel
                                                                        }
                                                                        <span className="ml-3">
                                                                            Activation:{" "}
                                                                            {format(
                                                                                item.activatesAt,
                                                                                "dd/MM/yyyy"
                                                                            )}
                                                                        </span>
                                                                        <span className="ml-3">
                                                                            Expiry:{" "}
                                                                            {format(
                                                                                item.expiresAt,
                                                                                "dd/MM/yyyy"
                                                                            )}
                                                                        </span>
                                                                        <span className="ml-3">
                                                                            Available:{" "}
                                                                            {item.available ===
                                                                            null
                                                                                ? "∞"
                                                                                : item.available}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                            <Segment vertical>
                                                                <ValidationError field="contract" />
                                                                <label className="with-input">
                                                                    Contract
                                                                </label>
                                                                <Select
                                                                    search
                                                                    value={
                                                                        voucher
                                                                            .contract
                                                                            ?._id
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        {
                                                                            value,
                                                                        }
                                                                    ) => {
                                                                        onContractSelect(
                                                                            value
                                                                        );
                                                                    }}
                                                                    options={courseContracts.map(
                                                                        (
                                                                            contract,
                                                                            index
                                                                        ) => {
                                                                            return {
                                                                                key: index,
                                                                                text: contract.name,
                                                                                value: contract._id,
                                                                            };
                                                                        }
                                                                    )}
                                                                />
                                                            </Segment>
                                                        </>
                                                    )}{" "}
                                                </>
                                            )}
                                            <Segment vertical>
                                                <ValidationError field="activatesAt" />
                                                <label className="with-input">
                                                    Activation date <b>*</b>
                                                </label>
                                                <DatePicker
                                                    value={voucher.activatesAt}
                                                    onDayChange={(value) => {
                                                        voucher.useStateManager(
                                                            (model) => {
                                                                model.activatesAt =
                                                                    value;
                                                            }
                                                        );
                                                    }}
                                                    dayPickerProps={{
                                                        disabledDays:
                                                            disabledDatesActivation,
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field="expiresAt" />
                                                <label className="with-input">
                                                    Expiration date <b>*</b>
                                                </label>
                                                <DatePicker
                                                    value={voucher.expiresAt}
                                                    onDayChange={(value) => {
                                                        voucher.useStateManager(
                                                            (model) => {
                                                                model.expiresAt =
                                                                    value;
                                                            }
                                                        );
                                                    }}
                                                    dayPickerProps={{
                                                        disabledDays:
                                                            disabledDatesExpiry,
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field="allocated" />
                                                <label className="with-input">
                                                    Players <b>*</b>
                                                </label>
                                                {voucher.contract && (
                                                    <p>
                                                        <small>
                                                            Vouchers associated
                                                            with a contract must
                                                            have four players
                                                        </small>
                                                    </p>
                                                )}
                                                <NumberInput
                                                    min={1}
                                                    max={
                                                        voucher.contract ? 4 : 8
                                                    }
                                                    disabled={
                                                        voucher.contract !==
                                                        null
                                                    }
                                                    value={voucher.players}
                                                    onChange={(value) => {
                                                        voucher.useStateManager(
                                                            (model) => {
                                                                model.players =
                                                                    value;
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Segment>
                                            <Button
                                                size="large"
                                                floated="right"
                                                className="theme mt-3"
                                                primary
                                                onClick={store}
                                                disabled={saving}
                                                loading={saving}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
