import { useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function useReCaptcha() {
	const captchaRef = useRef(null);

	const ReCaptcha = useCallback(
		function () {
			return (
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}
					ref={captchaRef}
				/>
			);
		},
		[captchaRef],
	);

	return {
		ReCaptcha,
		captchaRef: captchaRef.current ?? {
			getValue: () => {
				return '';
			},
			reset: () => console.warn('Captcha no loaded'),
		},
	};
}
