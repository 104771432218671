import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import { Button, Dropdown } from "semantic-ui-react";
import PartnerClubContract from "../../models/PartnerClubContract";
import { format } from "date-fns";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";

const Index = ({ user, history }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [table, setTable] = useState({
        page: 1,
        term: "",
        sort: "createdAt",
        order: "desc",
        limit: 25,
    });
    const [filters, setFilters] = useState({
        club: null,
        status: null,
        contractType: null,
    });

    const fetchData = async (params) => {
        const { data, total } = await PartnerClubContract.index(params);
        setData(data);
        setLoading(false);
        setTable(params);
        setTotal(total);
    };

    useEffect(() => {
        fetchData(table);
    }, []);

    const onChangeFilter = (property, value) => {
        filters[property] = value;
        table.page = 1;
        setFilters(filters);
        setTable(table);
        fetchData(Object.assign({}, table, filters));
    };

    const headers = [
        { key: "name", text: "Name" },
        { key: "status", text: "Status" },
        { key: "activatesAt", text: "Activation" },
        { key: "expiresAt", text: "Expiration" },
        { key: "allocated", text: "Stock" },
        { key: "reserved", text: "Reserved" },
        { key: "used", text: "Used" },
        { key: "available", text: "Available" },
        { key: "course", text: "Course" },
        { key: "contractType", text: "Type" },
        { key: "profit", text: "Profit" },
        { key: "outstanding", text: "Outstanding" },
        { key: "actions", text: "Actions" },
    ];

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                <div
                    className="container-fluid"
                    style={{ overflowX: "scroll" }}
                >
                    <div className="row page-header mt-3">
                        <div className="col-sm-12 col-md-10">
                            <h2>Partner club contracts</h2>
                        </div>
                        <div className="col-sm-12 col-md-2 text-right">
                            <Link to="/partner-club-contracts/create">
                                <Button primary className="theme">
                                    Create contract
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>Filters</h3>
                            <GolfClubSearchSelect
                                placeholder="Filter by club"
                                value={filters.club}
                                onChange={(value) =>
                                    onChangeFilter("club", value)
                                }
                            />
                            <Dropdown
                                selection
                                placeholder={"Contract type"}
                                options={[
                                    { key: 0, value: "PCP", text: "PCP" },
                                    {
                                        key: 1,
                                        value: "CORPORATE",
                                        text: "Corporate",
                                    },
                                ]}
                                value={filters.contractType}
                                onChange={(_, { value }) =>
                                    onChangeFilter("contractType", value)
                                }
                                className={"ml-3"}
                            />
                            <Dropdown
                                selection
                                placeholder={"Status"}
                                options={[
                                    { key: 0, value: "ACTIVE", text: "Active" },
                                    {
                                        key: 1,
                                        value: "EXPIRED",
                                        text: "Expired",
                                    },
                                    {
                                        key: 2,
                                        value: "NOT_YET_ACTIVE",
                                        text: "Not yet active",
                                    },
                                ]}
                                value={filters.status}
                                onChange={(_, { value }) =>
                                    onChangeFilter("status", value)
                                }
                                className={"ml-3"}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <DataTable
                                loading={loading}
                                headers={headers}
                                data={data.map((item) => {
                                    return {
                                        name: {
                                            value: item.name,
                                        },
                                        status: {
                                            value: item.statusLabel,
                                        },
                                        activatesAt: {
                                            value: format(
                                                item.activatesAt,
                                                "dd/MM/yyyy"
                                            ),
                                        },
                                        expiresAt: {
                                            value: format(
                                                item.expiresAt,
                                                "dd/MM/yyyy"
                                            ),
                                        },
                                        allocated: {
                                            value:
                                                item.allocated === null
                                                    ? "∞"
                                                    : item.allocated,
                                        },
                                        reserved: {
                                            value:
                                                item.allocated === null
                                                    ? "n/a"
                                                    : item.reserved,
                                        },
                                        used: {
                                            value: item.used,
                                        },
                                        available: {
                                            value:
                                                item.available === null
                                                    ? "∞"
                                                    : item.available,
                                        },
                                        course: {
                                            value: (
                                                <Link
                                                    to={`/golf-clubs/${item.course.club._id}`}
                                                >
                                                    {item.course.longName}
                                                </Link>
                                            ),
                                        },
                                        contractType: {
                                            value: item.contractType,
                                        },
                                        profit: {
                                            value: `£${item.profit}`,
                                        },
                                        outstanding: {
                                            value: `£${item.outstanding}`,
                                        },
                                        actions: {
                                            value: (
                                                <>
                                                    <Link
                                                        to={`/partner-club-contracts/${item._id}`}
                                                    >
                                                        <Button
                                                            primary
                                                            className="theme"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                </>
                                            ),
                                        },
                                    };
                                })}
                                totalRecords={total}
                                page={table.page}
                                onChange={fetchData}
                                searchTerm={table.term}
                                sort={table.sort}
                                order={table.order}
                                limit={table.limit}
                                sortOptions={[
                                    { text: "Creation", key: "createdAt" },
                                    { text: "Enabled", key: "isHidden" },
                                ]}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Index);
