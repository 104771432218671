import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Label, Dropdown } from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";

import NotificationActions from "../../store/actions/notification";
import PurchaseTransaction from "../../models/PurchaseTransaction";

class PurchasesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transactions: [],
            filters: {
                status: null,
                productType: null,
                user: null,
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.productTypeOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Credit",
                value: "CREDIT",
            },
            {
                key: 2,
                text: "Membership",
                value: "MEMBERSHIP",
            },
        ];

        this.statusOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Completed",
                value: "COMPLETED",
            },
            {
                key: 2,
                text: "Pending",
                value: "PENDING",
            },
            {
                key: 3,
                text: "Payment failed",
                value: "PAYMENT_FAILED",
            },
        ];
    }

    componentDidMount() {
        this.getTransactions(this.state.table);
    }

    async getTransactions(params) {
        this.setState({ loading: true });
        const { data, total } = await PurchaseTransaction.index(
            Object.assign(params, this.state.filters)
        );

        this.setState({
            loading: false,
            transactions: data,
            table: params,
            totalRecords: total,
        });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getTransactions(table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Date", width: 3 },
            { key: "amount", text: "Amount", width: 2 },
            { key: "status", text: "Status" },
            { key: "productType", text: "Type" },
            { key: "user", text: "Member" },
        ];

        const data = this.state.transactions.map((transaction) => {
            const { user } = transaction;

            return {
                createdAt: {
                    value: this.formatDate(transaction.createdAt),
                },
                amount: {
                    value: `£${transaction.amount}`,
                },
                status: {
                    value: this.getTransactionStatusLabel(transaction.status),
                },
                productType: {
                    value:
                        transaction.productType.charAt(0) +
                        transaction.productType.substring(1).toLowerCase(),
                },
                user: {
                    value: this.getUserLink(user),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getTransactions.bind(this)}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Amount", key: "amount" },
                    { text: "Status", key: "status" },
                ]}
                pagination
                hideSearch
            />
        );
    }

    getUserLink(user) {
        const { _id, name } = user;
        return <Link to={`/members/${_id}`}>{name}</Link>;
    }

    getTransactionStatusLabel(status) {
        let labelColor = "blue";
        if (status === "COMPLETED") {
            labelColor = "green";
        }
        if (status === "PAYMENT_FAILED") {
            labelColor = "red";
        }

        return (
            <Label color={labelColor}>
                {status.charAt(0) +
                    status.substring(1).toLowerCase().replace("_", " ")}
            </Label>
        );
    }

    formatDate(date) {
        return moment(date).format("DD/MM/YYYY, HH:mm");
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>App purchases (paid via Stripe)</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Dropdown
                            selection
                            placeholder={"Status"}
                            options={this.statusOptions}
                            value={this.state.filters.status}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("status", value)
                            }
                            className={"ml-3"}
                        />
                        <Dropdown
                            selection
                            placeholder={"Product type"}
                            options={this.productTypeOptions}
                            value={this.state.filters.productType}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("productType", value)
                            }
                            className={"ml-3"}
                        />
                        <MemberSearchSelect
                            placeholder="Filter by member"
                            value={this.state.filters.user}
                            onChange={(value) =>
                                this.onChangeFilter("user", value)
                            }
                            className="ml-3"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(PurchasesIndex);
