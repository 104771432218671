import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { Button, Segment } from "semantic-ui-react";
import styles from "./show.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import EntityAdmin from "../../../models/EntityAdmin";

import Role from "../../../models/Role";
import EmailAddresses from "../../../assets/components/user/EmailAddresses";
import Details from "../../../assets/components/user/Details";
import Roles from "../../../assets/components/user/Roles";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import ImageCropper from "../../../assets/components/ImageCropper";
import CenterLoader from "../../../assets/components/CenterLoader";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingAdmin: true,
            loadingRoles: true,
            error: null,
            admin: new EntityAdmin(),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.getAdmin(id);
        this.getRoles();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    update(admin) {
        this.setState({ admin });
    }

    getAdmin(id) {
        EntityAdmin.show(id)
            .then((admin) => {
                this.setState({
                    loadingAdmin: false,
                    admin,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingAdmin: false,
                    loadingRoles: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    getRoles() {
        Role.index()
            .then(({ data }) => {
                const include = ["APP_USER", "ENTITY_ADMIN"];
                const roles = data.filter(
                    (role) => include.includes(role.key) === true
                );
                this.setState({ loadingRoles: false, roles });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingAdmin: false,
                    loadingRoles: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    onChangeProfileImage(base64ImageString) {
        const { admin } = this.state;
        admin.updateProfileImage(base64ImageString).then((response) => {
            admin.profileImage = response.data.data.profileImage;
            this.setState({ admin });
        });
    }

    save() {
        this.setState({ saving: true });
        this.state.admin
            .update()
            .then(() => {
                this.setState({ saving: false });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "Admin saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save user");
                this.handleValidationErrors(error);
            });
    }

    saveRoles() {
        this.setState({ saving: true });
        this.state.admin
            .updateRoles()
            .then(() => {
                this.setState({ saving: false });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "Admin saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save user");
                this.handleValidationErrors(error);
            });
    }

    loaded() {
        return (
            this.state.loadingAdmin === false &&
            this.state.loadingRoles === false
        );
    }

    userBasic() {
        if (this.loaded() === false) {
            return null;
        }

        return (
            <div className={styles.topSegment + " skinny"}>
                <div className="row skinny">
                    <div className="col text-center">
                        <img
                            className="profile-logo-lg"
                            src={this.state.admin.profileImageUrl}
                        />
                        <ImageCropper
                            onChange={this.onChangeProfileImage.bind(this)}
                        />
                        <h3>{this.state.admin.name}</h3>
                        <p>{this.state.admin.email}</p>
                    </div>
                </div>
            </div>
        );
    }

    rolesTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Roles</h3>
                <p>Roles apply groups of permissions to a user.</p>
                <Roles
                    roles={this.state.roles}
                    user={this.state.admin}
                    save={this.saveRoles.bind(this)}
                />
            </div>
        );
    }

    detailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the user's details.</p>
                <Details
                    update={this.update.bind(this)}
                    user={this.state.admin}
                />
                <EmailAddresses
                    update={this.update.bind(this)}
                    user={this.state.admin}
                />
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                {this.userBasic()}
                <div className="row skinny">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.detailsTab()}
                            </Tab>
                            <Tab
                                eventKey="rolesPermissions"
                                title="Roles & Permissions"
                            >
                                {this.rolesTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
