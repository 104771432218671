import React, { Fragment, useState } from "react";
import { Button } from "semantic-ui-react";
import styles from "./style.module.scss";
import logo from "../../../assets/images/mashie-shield-with-text-white.png";
import logoDark from "../../../assets/images/mashie-shield-with-text.png";
import { useEffect } from "react";
import Event from "../../../models/Event";
import { format } from "date-fns";
import SmallHeader from "./components/SmallHeader";
import { Separator } from "./components/Separator";
import { ClubAddress } from "./components/ClubAddress";

const logos = [
  "taylormade",
  "gusborne",
  "virgin",
  "stewart",
  "vivir",
  "signal",
  "amdaris",
  "sonder",
  "stance",
  "golfbreaks",
];

export default function EventIndex() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let referrer = params.get("referrer");
    if (referrer !== null) {
      localStorage.setItem("referrer", referrer);
    }

    setLoading(true);

    const getEvents = async () => {
      try {
        const data = await Event.getPublicEvents();
        setEvents(data.data);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    getEvents();
  }, []);

  if (loading || events.length === 0) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="app events" style={{ paddingBottom: "100px" }}>
      <SmallHeader />
      <div className={styles.fullHeader} alt="event">
        <img className={styles.entityLogo} src={logo} alt="logo" />
        <h1 className={styles.headerText}>CLUB MEMBER EVENTS</h1>
        <h3 className={styles.whiteText}>
          HOSTED EVENTS FOR MEMBERS & GUESTS AT THE MOST EXCLUSIVE VENUES
        </h3>
      </div>
      <div className={styles.contentContainer}>
        <h3 className={styles.h3}>PREMIUM GOLF EXPERIENCES</h3>
        <p>
          As well as delivering a calendar of high quality society days for the
          MASHIE Members Club, MASHIE can organise private golf days for your
          business, charity or group.
        </p>
        {events.series.map((series) => {
          return (
            <Fragment key={series._id}>
              <Separator />
              <div className={styles.flexSpread}>
                <img
                  style={{
                    width: "175px",
                    height: "100%",
                  }}
                  className={styles.hiddenSmall}
                  src={logoDark}
                  alt="logo"
                />
                <h2 className={styles.subheader}>
                  {series.name.toUpperCase()}
                </h2>
                <img
                  alt="series partner logo"
                  style={{
                    width: "175px",
                    height: "100%",
                  }}
                  src={
                    series.image
                      ? `${process.env.REACT_APP_CONTENT_URL}event-series/images/${series.image}`
                      : logo
                  }
                />
              </div>
              <table className={styles.table} key={series.name}>
                <thead className={styles.thead}>
                  <tr className={styles.tr}>
                    <th className={styles.td} style={{ width: "20%" }}>Date</th>
                    <th className={styles.td} style={{ width: "20%" }}>Event Name</th>
                    <th className={styles.td} style={{ width: "25%" }}>Location</th>
                    <th style={{ width: "25%" }} className={[styles.hiddenSmall, styles.th]}>
                      Bookable by
                    </th>
                    <th className={styles.th} style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {series.events.map((event) => {
                    return (
                      <tr key={event._id} className={styles.tr}>
                        <td className={styles.td}>
                          {format(new Date(event.date), "EEEE do MMMM yyyy")}
                        </td>
                        <td className={styles.td}>
                          {event.name}{" "}
                          {event.hostedBy !== undefined &&
                            ` - hosted by ${event.hostedBy}`}
                        </td>
                        <td className={styles.td}>
                          {event?.club ? (
                            event?.club.name
                          ) : (
                            <ClubAddress event={event} />
                          )}
                        </td>
                        <td className={[styles.hiddenSmall, styles.td]}>
                          {event.bookableDescription}
                        </td>
                        <td className={styles.td}>
                          <Button
                            primary
                            className={styles.eventButton}
                            disabled={false}
                            onClick={() => {
                              window.location.href = `/public/events/${event._id}`;
                            }}
                            style={{ width: "100px" }}
                          >
                            VIEW
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          );
        })}
        <h2 className="mt-5">SPONSORS</h2>
        <div className={styles.imageContainer}>
          {logos.map((logo) => (
            <img
              key={logo}
              alt={`${logo} logo`}
              src={require(`../../../assets/images/sponsors/${logo}.png`)}
            />
          ))}
        </div>
      </div>
      <SmallHeader bottom />
    </div>
  );
}
