export default [
	{
		key: 0,
		text: 'Agriculture, Forestry, and Fishing',
		value: 'agricultureForestryFishing',
	},
	{
		key: 1,
		text: 'Mining and Natural Resources',
		value: 'miningNaturalResources',
	},
	{ key: 2, text: 'Construction', value: 'construction' },
	{ key: 3, text: 'Manufacturing', value: 'manufacturing' },
	{ key: 4, text: 'Utilities (Energy, Water, etc.)', value: 'utilities' },
	{ key: 5, text: 'Wholesale Trade', value: 'wholesaleTrade' },
	{ key: 6, text: 'Retail Trade', value: 'retailTrade' },
	{
		key: 7,
		text: 'Transportation and Logistics',
		value: 'transportationLogistics',
	},
	{
		key: 8,
		text: 'Information Technology and Software',
		value: 'informationTechnologySoftware',
	},
	{ key: 9, text: 'Telecommunications', value: 'telecommunications' },
	{
		key: 10,
		text: 'Healthcare and Life Sciences',
		value: 'healthcareLifeSciences',
	},
	{ key: 11, text: 'Finance and Banking', value: 'financeBanking' },
	{ key: 12, text: 'Insurance', value: 'insurance' },
	{
		key: 13,
		text: 'Real Estate and Property Management',
		value: 'realEstatePropertyManagement',
	},
	{ key: 14, text: 'Education and Training', value: 'educationTraining' },
	{
		key: 15,
		text: 'Professional Services (Consulting, Legal, Accounting)',
		value: 'professionalServicesConsultingLegalAccounting',
	},
	{ key: 16, text: 'Media and Entertainment', value: 'mediaEntertainment' },
	{ key: 17, text: 'Hospitality and Tourism', value: 'hospitalityTourism' },
	{
		key: 18,
		text: 'Government and Public Administration',
		value: 'governmentPublicAdministration',
	},
	{
		key: 19,
		text: 'Non-Profit and Social Services',
		value: 'nonProfitSocialServices',
	},
	{ key: 20, text: 'Other', value: 'other' },
];
