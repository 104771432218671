export const ClubAddress = ({ event }) => {
	let address1 = "";
	if (
	  typeof event.locationAddressLine1 !== "undefined" &&
	  event.locationAddressLine1 !== ""
	) {
	  address1 = `${event.locationAddressLine1}, `;
	}
  
	let address2 = "";
	if (
	  typeof event.locationAddressLine2 !== "undefined" &&
	  event.locationAddressLine2 !== ""
	) {
	  address2 = `${event.locationAddressLine2}, `;
	}
  
	let town = "";
	if (
	  typeof event.locationAddressTown !== "undefined" &&
	  event.locationAddressTown !== ""
	) {
	  town = `${event.locationAddressTown}, `;
	}
  
	let county = "";
	if (
	  typeof event.locationAddressCounty !== "undefined" &&
	  event.locationAddressCounty !== ""
	) {
	  county = `${event.locationAddressCounty}, `;
	}
  
	let country = "";
	if (
	  typeof event.locationAddressCountry !== "undefined" &&
	  event.locationAddressCountry !== ""
	) {
	  country = `${event.locationAddressCountry}, `;
	}
  
	let postcode = "";
	if (
	  typeof event.locationAddressPostcode !== "undefined" &&
	  event.locationAddressPostcode !== ""
	) {
	  postcode = event.locationAddressPostcode;
	}
  
	const address = address1 + address2 + town + county + country + postcode;
	if (address.charAt(address.length - 2) === ",")
	  return address.substring(0, address.length - 2);
	return address;
  };