import React, { Component } from "react";
import {
    Input,
    Segment,
    Select,
    Checkbox,
    Modal,
    Button,
    Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import NumberInput from "../../assets/components/NumberInput";
import DataTable from "../../assets/components/dataTable";
import CenterLoader from "../../assets/components/CenterLoader";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import HtmlEditor from "../../assets/components/HtmlEditor";
import Event from "../../models/Event";
import GolfClub from "../../models/GolfClub";
import Member from "../../models/Member";
import EventBooking from "../../models/EventBooking";
import EventSeries from "../../models/EventSeries";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import Location from "../../assets/components/event/Location";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingEvent: true,
            loadingEventSeries: true,
            loadingBookings: true,
            loadingReserves: true,
            error: null,
            notification: null,
            event: new Event(),
            newBooking: new EventBooking({ players: 1 }),
            showNewBookingErrorModal: false,
            bookingUser: {},
            eventSeries: [],
            bookings: [],
            reserveBookings: [],
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.categories = [
            { key: 0, text: "Member", value: "member" },
            { key: 1, text: "Public", value: "public" },
            { key: 2, text: "Roll-up", value: "roll-up" },
        ];

        this.partnerTypes = [
            { key: 0, text: "Partner", value: "partner" },
            { key: 1, text: "Headline", value: "headline" },
        ];

        this.roles = [
            {
                key: 0,
                value: "MASHIE_COUNTRY_MEMBERSHIP",
                text: "Country members",
            },
            { key: 1, value: "MASHIE_CLUB_MEMBERSHIP", text: "Club members" },
            { key: 2, value: "MASHIE_TRIAL_MEMBERSHIP", text: "Trial members" },
            {
                key: 3,
                value: "MASHIE_FOUNDER_MEMBERSHIP",
                text: "Founder members",
            },
            { key: 4, value: "MASHIE_LINKS_MEMBERSHIP", text: "Links members" },
        ];

        this.bookingLinkTypes = [
            { key: 0, text: "URL", value: "url" },
            { key: 1, text: "Phone number", value: "phoneNumber" },
            { key: 2, text: "Email address", value: "email" },
        ];
    }

    componentDidMount() {
        this.getEvent();
        this.getEventSeries();
        this.getBookings();
        this.getReserveBookings();
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    onChangeDescription(text) {
        const { event } = this.state;
        event.description = text;
        this.setState({ event });
    }

    onChangeTermsAndConditions(text) {
        const { event } = this.state;
        event.termsAndConditions = text;
        this.setState({ event });
    }

    onChangeFreeTransaction(event) {
        const { newBooking } = this.state;
        const checked = !event.target.previousSibling.checked;
        newBooking.isFree = checked;
        this.setState({ newBooking });
    }

    addPartnerImage(files) {
        const { event } = this.state;
        const fileReader = new FileReader();
        if (files.length > 0) {
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = async () => {
                const image = await event.storePartnerImage(fileReader.result);
                event.partnerImages.push(image);
                this.props.dispatch(
                    NotificationActions.create({
                        type: "success",
                        text: "Image saved",
                    })
                );
                this.setState({ event });
            };
        }
    }

    removePartnerImage(index) {
        const { event } = this.state;
        const image = event.partnerImages[index];
        event.deletePartnerImage(image._id);
        event.partnerImages.splice(index, 1);
        this.props.dispatch(
            NotificationActions.create({
                type: "success",
                text: "Image removed",
            })
        );
        this.setState({ event });
    }

    addPartnerImageButton() {
        return (
            <div className="row">
                <div className="col">
                    <Segment vertical>
                        <input
                            type="file"
                            id="newPartnerImageFileInput"
                            className="hidden"
                            onChange={({ target }) =>
                                this.addPartnerImage(target.files)
                            }
                        />
                        <Button
                            primary
                            className="theme center"
                            onClick={() =>
                                document
                                    .getElementById("newPartnerImageFileInput")
                                    .click()
                            }
                        >
                            Add image
                        </Button>
                    </Segment>
                </div>
            </div>
        );
    }

    onChangePartnerImageType(index, value) {
        const { event } = this.state;
        event.partnerImages[index].partnerType = value;
        event.updatePartnerImage(event.partnerImages[index]._id, {
            partnerType: value,
        });
        this.props.dispatch(
            NotificationActions.create({
                type: "success",
                text: "Image updated",
            })
        );
        this.setState({ event });
    }

    listPartnerImages() {
        if (this.state.event.partnerImages.length === 0) {
            return (
                <div className="col">
                    <p className="text-center">
                        This event has no partner images yet
                    </p>
                </div>
            );
        }

        return this.state.event.partnerImages.map((image, index) => {
            return (
                <div className="col-3 mt-3" key={index}>
                    <div className={styles.partnerImagePreviewContainer}>
                        <img
                            className={styles.partnerImagePreview}
                            id={"imagePreview-" + index}
                            src={Event.partnerImage(image)}
                        />
                        <Button
                            primary
                            onClick={() => this.removePartnerImage(index)}
                            className={
                                styles.partnerImagePreviewDeleteButton + " red"
                            }
                        >
                            Remove
                        </Button>
                    </div>
                    <label className="with-input mt-3">Partner type</label>
                    <Select
                        fluid
                        placeholder="Select a partner type"
                        options={this.partnerTypes}
                        value={image.partnerType}
                        className="mt-2"
                        name={index}
                        onChange={(_, { value }) =>
                            this.onChangePartnerImageType(index, value)
                        }
                    />
                </div>
            );
        });
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    async getUser(id) {
        try {
            const bookingUser = await Member.show(id);
            this.setState({ bookingUser });
        } catch (_) {
            this.notification("error", "Failed to user, please try again");
        }
    }

    insufficientCreditModal() {
        const payableAmount =
            this.state.event.price * 1.2 * this.state.newBooking.players;
        const calculateRemainingCredit = parseFloat(
            (payableAmount - this.state.bookingUser.eventCreditBalance).toFixed(
                2
            )
        );
        return (
            <Modal open={this.state.showNewBookingErrorModal}>
                <Modal.Header>Insufficent Funds</Modal.Header>
                <Modal.Content>
                    <div className="row">
                        <div className="col text-center">
                            <h3 className={styles.generalHeading}>
                                This member doesn't have enough credit to book
                                onto this event.
                            </h3>
                            <p className={styles.generalText}>
                                <span className={styles.bold}>
                                    {this.state.bookingUser.name}
                                </span>{" "}
                                currently has{" "}
                                <span className={styles.bold}>
                                    £{this.state.bookingUser.eventCreditBalance}
                                </span>{" "}
                                in their event credit.
                            </p>
                            <p className={styles.generalText}>
                                They will need{" "}
                                <span className={styles.bold}>
                                    £{calculateRemainingCredit}
                                </span>{" "}
                                adding to their account to book onto this event.
                            </p>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={() =>
                            this.setState({ showNewBookingErrorModal: false })
                        }
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    changeImage(files) {
        const { event } = this.state;
        const fileReader = new FileReader();
        if (files.length > 0) {
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = () => {
                event.updateImage(fileReader.result).then(({ image }) => {
                    event.image = image;
                    this.setState({ event });
                });
            };
        }
    }

    addImage() {
        return (
            <div className="row">
                <div className="col">
                    <Segment vertical>
                        <input
                            type="file"
                            id="imageFileInput"
                            className="hidden"
                            onChange={({ target }) =>
                                this.changeImage(target.files)
                            }
                        />
                        <Button
                            primary
                            className="theme center"
                            onClick={() =>
                                document
                                    .getElementById("imageFileInput")
                                    .click()
                            }
                        >
                            Select image
                        </Button>
                    </Segment>
                </div>
            </div>
        );
    }

    getEvent() {
        const { id } = this.props.match.params;
        Event.show(id)
            .then((event) => {
                const newBooking = new EventBooking({
                    event: event._id,
                    players: 1,
                    playerDetails: [],
                });
                this.setState({ loadingEvent: false, event, newBooking });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification("error", "Failed to event, please try again");
            });
    }

    getBookings() {
        const { id } = this.props.match.params;
        Event.bookingsIndex(id)
            .then((bookings) => {
                this.setState({
                    bookings,
                    loadingBookings: false,
                });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification(
                    "error",
                    "Failed to load event cancelled, please try again"
                );
            });
    }

    getReserveBookings() {
        const { id } = this.props.match.params;
        Event.reservations(id)
            .then((reserveBookings) => {
                this.setState({ loadingReserves: false, reserveBookings });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification(
                    "error",
                    "Failed to get event reserves, please try again"
                );
            });
    }

    gatherInput(property, value) {
        const { event } = this.state;
        event[property] = value;
        this.setState({ event });
    }

    save() {
        this.setState({ saving: true });
        const { event } = this.state;

        event
            .update()
            .then(() => {
                this.setState({ saving: false });
                this.notification("success", "Event saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save event");
                this.handleValidationErrors(error);
            });
    }

    delete() {
        const confirm = window.confirm(
            "Are you sure you want to delete this event? This cannot be undone."
        );
        if (confirm === true) {
            const { event } = this.state;

            event
                .delete()
                .then(() => {
                    this.notification("success", "Event deleted");
                    this.props.history.push("/events");
                })
                .catch((error) => {
                    this.notification("error", "Failed to delete event");
                    this.handleValidationErrors(error);
                });
        }   
    }

    loaded() {
        return (
            this.state.loadingEvent === false &&
            this.state.loadingEventSeries === false &&
            this.state.loadingBookings === false &&
            this.state.loadingReserves === false
        );
    }

    showImage() {
        if (typeof this.state.event.image === "undefined") {
            return <p>Please select an image for this event</p>;
        }

        return (
            <img
                className={styles.eventImagePreview}
                src={this.state.event.imageUrl}
            />
        );
    }

    onChangeCategory(e, result) {
        const { event } = this.state;
        event.category = result.value;
        this.setState({ event });
    }

    onChangeBookingLinkType(e, result) {
        const { event } = this.state;
        event.bookingLinkType = result.value;
        this.setState({ event });
    }

    handleClear(property) {
        const { event } = this.state;
        event[property] = null;
        this.setState({ event });
    }

    formatSeriesOptions() {
        return this.state.eventSeries.map((series, index) => {
            return { key: index, value: series._id, text: series.name };
        });
    }

    onChangeSeries(e, result) {
        const { event } = this.state;
        event.series = result.value;
        this.setState({ event });
    }

    onChangeShowForRoles(e, result) {
        const { event } = this.state;
        event.showForRoles = result.value;
        this.setState({ event });
    }

    onChangeBookForRoles(e, result) {
        const { event } = this.state;
        event.bookForRoles = result.value;
        this.setState({ event });
    }

    async onChangeClub(value) {
        const { event } = this.state;
        const club = await GolfClub.show(value);
        event.club = value;
        event.coordinates = club.hasValidCoordinates() ? club.location : [];
        event.locationAddressLine1 = club.address1;
        event.locationAddressLine2 = club.address2;
        event.locationAddressTown = club.town;
        event.locationAddressCounty = club.county;
        event.locationAddressPostcode = club.postcode;
        event.locationAddressCountry = club.country;
        this.setState({ event });
    }

    onChangeDate(selectedDay, modifiers, dayPickerInput) {
        const { event } = this.state;
        event.date = selectedDay;
        this.setState({ event });
    }

    onChangeBookingCutOff(selectedDay, modifiers, dayPickerInput) {
        const { event } = this.state;
        event.bookingCutOff = selectedDay;
        this.setState({ event });
    }

    onChangeAmendCutOff(selectedDay, modifiers, dayPickerInput) {
        const { event } = this.state;
        event.amendCutOff = selectedDay;
        this.setState({ event });
    }

    onChangeToggleAllowReviews() {
        const { event } = this.state;
        event.allowReviews = !event.allowReviews;
        this.setState({ event });
    }

    onChangeToggleNoGuests() {
        const { event } = this.state;
        event.noGuests = !event.noGuests;
        this.setState({ event });
    }

    onToggle(property) {
        const { event } = this.state;
        event[property] = event[property] === false;
        this.setState({ event });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    eventDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h2>Details</h2>
                <p>Edit the event's details.</p>
                <Segment vertical>
                    <h2>Event image</h2>
                    {this.errorsFor("image")}
                    {this.showImage()}
                    {this.addImage()}
                </Segment>
                <Segment vertical>
                    <div className="row">
                        <div className="col">
                            <h2>Partner images</h2>
                            {this.errorsFor("partnerImages")}
                        </div>
                    </div>
                    <div className="row">{this.listPartnerImages()}</div>
                    {this.addPartnerImageButton()}
                </Segment>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.event.name || ""}
                        onChange={({ target }) =>
                            this.gatherInput("name", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("price")}
                    <label className="with-input">Price</label>
                    <NumberInput
                        fluid
                        decimal
                        icon="gbp"
                        iconPosition="left"
                        name="price"
                        value={this.state.event.price}
                        onChange={(value) => this.gatherInput("price", value)}
                    />
                </Segment>
                <Segment vertical>
                    <p>Event category</p>
                    <p>Is this a public , members-only or roll-up event?</p>
                    <Select
                        fluid
                        value={this.state.event.category}
                        options={this.categories}
                        onChange={this.onChangeCategory.bind(this)}
                        placeholder="Select an event category"
                    />
                </Segment>
                <Segment vertical>
                    <p>Event series</p>
                    <div className={styles.containerStyles}>
                        <Icon
                            className={styles.iconStyles}
                            link
                            name="close"
                            onClick={() => this.handleClear("series")}
                        />
                        <Select
                            fluid
                            value={
                                this.state.event.series
                                    ? this.state.event.series
                                    : null
                            }
                            options={this.formatSeriesOptions()}
                            onChange={this.onChangeSeries.bind(this)}
                            placeholder="Select an event series"
                        />
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("hostedBy")}
                    <label className="with-input">Hosted by</label>
                    <p>
                        Provide the name of the host for this event (if
                        applicable).
                    </p>
                    <Input
                        fluid
                        name="hostedBy"
                        value={this.state.event.hostedBy || ""}
                        onChange={({ target }) =>
                            this.gatherInput("hostedBy", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("bookingLink")}
                    <label className="with-input">Booking link</label>
                    <p>
                        Provide an alternate way to book on to this event from
                        the app.
                    </p>
                    <Input
                        fluid
                        name="bookingLink"
                        value={this.state.event.bookingLink || ""}
                        onChange={({ target }) =>
                            this.gatherInput("bookingLink", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <p>Booking link type</p>
                    <p>
                        This will change how the button works in the app, make
                        sure this matches the booking link
                    </p>
                    <div className={styles.containerStyles}>
                        <Icon
                            className={styles.iconStyles}
                            link
                            name="close"
                            onClick={() => this.handleClear("bookingLinkType")}
                        />
                        <Select
                            fluid
                            value={this.state.event.bookingLinkType || ""}
                            options={this.bookingLinkTypes}
                            onChange={this.onChangeBookingLinkType.bind(this)}
                            placeholder="Select a booking link type"
                        />
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("bookingLinkText")}
                    <label className="with-input">Booking link text</label>
                    <p>Alternative text for the booking link</p>
                    <Input
                        fluid
                        name="bookingLinkText"
                        value={this.state.event.bookingLinkText || ""}
                        onChange={({ target }) =>
                            this.gatherInput("bookingLinkText", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <p>Visibility</p>
                    <p>
                        Define which membership types are able to see this event
                        within the app. Leave blank to hide this event entirely.
                    </p>
                    <Select
                        fluid
                        multiple
                        value={this.state.event.showForRoles}
                        options={this.roles}
                        onChange={this.onChangeShowForRoles.bind(this)}
                        placeholder="Select which memberships can view this event"
                    />
                </Segment>
                <Segment vertical>
                    <p>Bookability</p>
                    <p>
                        Define which membership types are able to book this
                        event within the app. Any membership types not included
                        here will not be able to book, and will instead see the
                        "Upgrade" button for this event within the app.
                    </p>
                    <Select
                        fluid
                        multiple
                        value={this.state.event.bookForRoles}
                        options={this.roles}
                        onChange={this.onChangeBookForRoles.bind(this)}
                        placeholder="Select which memberships can book this event"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("maximumAttendees")}
                    <label className="with-input">Maximum attendees</label>
                    <NumberInput
                        fluid
                        name="spacesLeftTrigger"
                        value={this.state.event.maxUsers}
                        onChange={(value) =>
                            this.gatherInput("maxUsers", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <p>No guest bookings</p>
                    <Checkbox
                        toggle
                        checked={this.state.event.noGuests}
                        onChange={this.onChangeToggleNoGuests.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("spacesLeftTrigger")}
                    <label className="with-input">
                        SPACES LEFT TRIGGER - This is the number at which point
                        the 'spaces left' pill will be shown against the events
                        list in the app.
                    </label>
                    <NumberInput
                        fluid
                        name="spacesLeftTrigger"
                        value={this.state.event.spacesLeftTrigger}
                        onChange={(value) =>
                            this.gatherInput("spacesLeftTrigger", value)
                        }
                    />
                </Segment>

                <Segment vertical>
                    {this.errorsFor("date")}
                    <label className="with-input">Event date</label>
                    <p>
                        <small>
                            The date on which the event is due to take place.
                        </small>
                    </p>
                    <DayPickerInput
                        placeholder="Select event date"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={this.DatePickerInput}
                        format="DD/MM/YYYY"
                        value={moment(this.state.event.date).format(
                            "DD/MM/YYYY"
                        )}
                        onDayChange={this.onChangeDate.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("bookingCutOff")}
                    <label className="with-input">
                        Bookings & additional players cut-off
                    </label>
                    <p>
                        <small>
                            The date from which app users will no longer be able
                            to make new bookings for the event. Users will also
                            be unable to add additional players to existing
                            bookings.
                        </small>
                    </p>
                    <DayPickerInput
                        placeholder="Select event date"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={this.DatePickerInput}
                        format="DD/MM/YYYY"
                        value={moment(
                            this.state.event.bookingCutOff
                                ? this.state.event.bookingCutOff
                                : this.state.event.date
                        ).format("DD/MM/YYYY")}
                        onDayChange={this.onChangeBookingCutOff.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("amendCutOff")}
                    <label className="with-input">
                        Cancellation and player removal cut-off
                    </label>
                    <p>
                        <small>
                            The date from which app users will no longer be able
                            to cancel bookings for the event. Users will also be
                            unable to remove players from existing bookings.
                        </small>
                    </p>
                    <DayPickerInput
                        placeholder="Select event date"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={this.DatePickerInput}
                        format="DD/MM/YYYY"
                        value={moment(
                            this.state.event.amendCutOff
                                ? this.state.event.amendCutOff
                                : this.state.event.date
                        ).format("DD/MM/YYYY")}
                        onDayChange={this.onChangeAmendCutOff.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <p>Do you want to allow attendees to review this event?</p>
                    <p>
                        If enabled, attendees will receive an email the day
                        after the event inviting them to review it.
                    </p>
                    <Checkbox
                        toggle
                        label="Allow reviews"
                        checked={this.state.event.allowReviews}
                        onChange={this.onChangeToggleAllowReviews.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Club</label>
                    <GolfClubSearchSelect
                        fluid
                        value={this.state.event.club ?? null}
                        onChange={(value) => this.onChangeClub(value)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressLine1")}
                    <label className="with-input">Address line 1</label>
                    <Input
                        fluid
                        name="locationAddressLine1"
                        value={this.state.event.locationAddressLine1 || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressLine1",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressLine2")}
                    <label className="with-input">Address line 2</label>
                    <Input
                        fluid
                        name="locationAddressLine2"
                        value={this.state.event.locationAddressLine2 || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressLine2",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressTown")}
                    <label className="with-input">Town</label>
                    <Input
                        fluid
                        name="locationAddressTown"
                        value={this.state.event.locationAddressTown || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressTown",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressCounty")}
                    <label className="with-input">County</label>
                    <Input
                        fluid
                        name="locationAddressCounty"
                        value={this.state.event.locationAddressCounty || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressCounty",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressPostcode")}
                    <label className="with-input">Postcode</label>
                    <Input
                        fluid
                        name="locationAddressPostcode"
                        value={this.state.event.locationAddressPostcode || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressPostcode",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("locationAddressCountry")}
                    <label className="with-input">Country</label>
                    <Input
                        fluid
                        name="locationAddressCountry"
                        value={this.state.event.locationAddressCountry || ""}
                        onChange={({ target }) =>
                            this.gatherInput(
                                "locationAddressCountry",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("description")}
                    <label className="with-input">Description</label>
                    <HtmlEditor
                        content={this.state.event.description}
                        onChange={this.onChangeDescription.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("termsAndConditions")}
                    <label className="with-input">Terms & Conditions</label>
                    <HtmlEditor
                        content={this.state.event.termsAndConditions}
                        onChange={this.onChangeTermsAndConditions.bind(this)}
                    />
                </Segment>
                <Segment vertical>
            
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                    {this.state.bookings.length === 0 && this.state.loadingBookings === false && 
                (<Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        color='red'
                        onClick={this.delete.bind(this)}
                    >
                        Delete
                    </Button>
                    )}
                </Segment>
            </div>
        );
    }

    numberWithCommas(number) {
        return parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getBookingTotal() {
        var bookingTotal = 0;
        var playerTotal = 0;
        const activeBookings = this.state.bookings.filter(
            ({ cancelledAt }) => cancelledAt === undefined
        );
        activeBookings.map((booking) => {
            bookingTotal +=
                (booking.bookedPrice
                    ? booking.bookedPrice
                    : this.state.event.price) * booking.players;
            playerTotal += booking.players;
        });

        return (
            <>
                <p>
                    <b>Total price:</b> £{this.numberWithCommas(bookingTotal)}{" "}
                    (£
                    {this.numberWithCommas(bookingTotal * 1.2)} inc VAT)
                </p>
                {this.state.event.price > 0 && (
                    <>
                        <p>
                            <b>Total cost:</b> £
                            {this.numberWithCommas(
                                playerTotal * this.state.event.price
                            )}
                        </p>
                        <p>
                            <b>Total profit:</b> £
                            {this.numberWithCommas(
                                bookingTotal -
                                    playerTotal * this.state.event.price
                            )}
                        </p>
                    </>
                )}
            </>
        );
    }

    bookingsTable() {
        const headers = [
            { key: "bookingDate", text: "Booking date" },
            { key: "name", text: "Name" },
            { key: "entity", text: "Entity" },
            { key: "bookedPrice", text: "Booked price" },
            { key: "players", text: "Players" },
            { key: "membership", text: "Membership type" },
            { key: "note", text: "Latest Note" },
            { key: "actions", text: "Actions" },
        ];

        const active = this.state.bookings.filter(
            ({ cancelledAt }) => cancelledAt === undefined
        );
        const data = active.map((booking) => {
            return {
                bookingDate: {
                    value: moment(booking.createdAt).format(
                        "DD/MM/YYYY: HH:mm"
                    ),
                    search: moment(booking.createdAt).format("DD/MM/YYYY"),
                },
                name: {
                    value: (
                        <>
                            <Link to={`/members/${booking.user._id}`}>
                                {booking.user.name}{" "}
                                {booking.user.CONGUHandicap
                                    ? `(${booking.user.CONGUHandicap})`
                                    : null}
                            </Link>
                            {booking.playerDetails.length > 0 && (
                                <ul className={styles.guestList}>
                                    {booking.playerDetails.map(
                                        (player, index) => {
                                            if (player.name !== "") {
                                                return (
                                                    <li key={index}>
                                                        {player.name}{" "}
                                                        {player.handicap
                                                            ? `(${player.handicap})`
                                                            : null}
                                                    </li>
                                                );
                                            }
                                        }
                                    )}
                                </ul>
                            )}
                        </>
                    ),
                    search: booking.user.name,
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${booking.user.entity.entity._id}`}
                        >
                            {booking.user.entity.entity.name}
                        </Link>
                    ),
                    search: booking.user.entity.entity.name,
                },
                bookedPrice: {
                    value:
                        booking.bookedPrice > 0
                            ? `£${this.numberWithCommas(
                                  booking.bookedPrice
                              )} (£${this.numberWithCommas(
                                  booking.bookedPrice * 1.2
                              )} inc VAT)`
                            : 0,
                },
                players: { value: booking.players, search: booking.players },
                membership: { value: booking.user.getMembershipStatus().label },
                note: {
                    value:
                        booking.internalNotes.length > 0
                            ? booking.internalNotes.slice(-1)[0].note
                            : "-",
                },
                actions: {
                    value: (
                        <>
                            <Link to={`bookings/${booking._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                            <Button
                                primary
                                className="red"
                                onClick={() => this.deleteBooking(booking)}
                            >
                                Cancel
                            </Button>
                        </>
                    ),
                },
            };
        });

        return <DataTable headers={headers} data={data} />;
    }

    cancelledBookingsTable() {
        const headers = [
            { key: "bookingDate", text: "Booking date" },
            { key: "name", text: "Name" },
            { key: "entity", text: "Entity" },
            { key: "bookedPrice", text: "Booked price" },
            { key: "players", text: "Total" },
            { key: "membership", text: "Membership type" },
        ];

        const cancelled = this.state.bookings.filter(
            ({ cancelledAt }) => cancelledAt !== undefined
        );
        const data = cancelled.map((booking) => {
            return {
                bookingDate: {
                    value: moment(booking.createdAt).format(
                        "DD/MM/YYYY: HH:mm"
                    ),
                    search: moment(booking.createdAt).format("DD/MM/YYYY"),
                },
                name: {
                    value: (
                        <>
                            <Link to={`/members/${booking.user._id}`}>
                                {booking.user.name}{" "}
                                {booking.user.CONGUHandicap
                                    ? `(${booking.user.CONGUHandicap})`
                                    : null}
                            </Link>
                            {booking.playerDetails.length > 0 && (
                                <ul className={styles.guestList}>
                                    {booking.playerDetails.map((player) => {
                                        if (player.name !== "") {
                                            return (
                                                <li>
                                                    {player.name}{" "}
                                                    {player.handicap
                                                        ? `(${player.handicap})`
                                                        : null}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                        </>
                    ),
                    search: booking.user.name,
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${booking.user.entity.entity._id}`}
                        >
                            {booking.user.entity.entity.name}
                        </Link>
                    ),
                    search: booking.user.entity.entity.name,
                },
                players: { value: booking.players, search: booking.players },
                bookedPrice: {
                    value:
                        booking.bookedPrice > 0
                            ? `£${booking.bookedPrice} (£${
                                  booking.bookedPrice * 1.2
                              } inc VAT)`
                            : null,
                },
                membership: { value: booking.user.getMembershipStatus().label },
            };
        });

        return <DataTable headers={headers} data={data} />;
    }

    reservesTable() {
        const headers = [
            { key: "reserveDate", text: "Reserve date" },
            { key: "name", text: "Name" },
            { key: "entity", text: "Entity" },
            { key: "players", text: "Total" },
            { key: "membership", text: "Membership type" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.reserveBookings.map((booking, index) => {
            return {
                reserveDate: {
                    value: moment(booking.createdAt).format(
                        "DD/MM/YYYY: HH:mm:ss"
                    ),
                    search: moment(booking.createdAt).format("DD/MM/YYYY"),
                },
                name: {
                    value: (
                        <>
                            <Link to={`/members/${booking.user._id}`}>
                                {booking.user.name}{" "}
                                {booking.user.CONGUHandicap
                                    ? `(${booking.user.CONGUHandicap})`
                                    : null}
                            </Link>
                        </>
                    ),
                    search: booking.user.name,
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${booking.user.entity.entity._id}`}
                        >
                            {booking.user.entity.entity.name}
                        </Link>
                    ),
                    search: booking.user.entity.entity.name,
                },
                players: { value: booking.players, search: booking.players },
                membership: { value: booking.user.getMembershipStatus().label },
                actions: {
                    value: (
                        <>
                            <Button
                                primary
                                className="red"
                                onClick={() => this.deleteReserve(index)}
                            >
                                Cancel
                            </Button>
                        </>
                    ),
                },
            };
        });

        return <DataTable headers={headers} data={data} />;
    }

    getEventSeries() {
        this.setState({ loading: true });
        EventSeries.list()
            .then((eventSeries) => {
                this.setState({
                    loadingEventSeries: false,
                    eventSeries,
                });
            })
            .catch((error) => {
                this.setState({ loadingEventSeries: false, error: true });
                this.notification(
                    "error",
                    "Failed to load event series, please try again"
                );
            });
    }

    onChangeMember(value) {
        const { newBooking } = this.state;
        newBooking.user = value;
        this.getUser(value);
        this.setState({ newBooking });
    }

    onChangeNewBookingNoOfPlayers(value) {
        const { newBooking } = this.state;
        newBooking.players = value;
        this.setState({ newBooking });
    }

    newEventBooking() {
        return new EventBooking({
            event: this.state.event._id,
            players: 1,
            playerDetails: [],
        });
    }

    addPlayerDetails() {
        const { newBooking } = this.state;
        newBooking.playerDetails.push({ name: "", handicap: 0 });
        this.setState({ newBooking });
    }

    deleteBooking(booking) {
        const confirm = window.confirm(
            "Are you sure you want to cancel this booking?"
        );
        if (confirm === true) {
            booking
                .delete()
                .then((respose) => {
                    this.notification("success", "Booking deleted");
                    this.getBookings();
                })
                .catch((error) => {
                    this.notification("error", "Failed to  delete booking");
                });
        }
    }

    deleteReserve(index) {
        const confirm = window.confirm(
            "Are you sure you want to cancel this reserve?"
        );
        const { reserveBookings } = this.state;
        const reserve = reserveBookings[index];
        if (confirm === true) {
            reserve
                .delete()
                .then(() => {
                    reserveBookings.splice(this.state.reserveBookings, 1);
                    this.setState({ reserveBookings });
                    this.notification("success", "Reserve deleted");
                })
                .catch(() => {
                    this.notification("error", "Failed to  delete reserve");
                });
        }
    }

    onChangePlayerDetailName(event) {
        const { value } = event.target;
        const { name } = event.target;
        const { newBooking } = this.state;
        newBooking.playerDetails[name].name = value;
        this.setState({ newBooking });
    }

    onChangePlayerDetailHandicap(event) {
        const { value } = event.target;
        const { name } = event.target;
        const { newBooking } = this.state;
        newBooking.playerDetails[name].handicap = value;
        this.setState({ newBooking });
    }

    removePlayerDetail(index) {
        const { newBooking } = this.state;
        newBooking.playerDetails.splice(index, 1);
        this.setState({ newBooking });
    }

    listPlayerDetails() {
        return this.state.newBooking.playerDetails.map((player, index) => {
            return (
                <div className="row mt-3" key={index}>
                    <div className="col-7">
                        <label className="with-input">Player name *</label>
                        <Input
                            fluid
                            placeholder="Player name"
                            name={index}
                            value={player.name}
                            onChange={this.onChangePlayerDetailName.bind(this)}
                        />
                    </div>
                    <div className="col-3">
                        <label className="with-input">Player handicap</label>
                        <Input
                            fluid
                            placeholder="Player handicap"
                            name={index}
                            value={player.handicap}
                            onChange={this.onChangePlayerDetailHandicap.bind(
                                this
                            )}
                        />
                    </div>
                    <div className="col-2 d-flex align-items-end text-right justify-content-end">
                        <Button
                            primary
                            className="red"
                            onClick={() => this.removePlayerDetail(index)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    saveNewBooking() {
        this.setState({
            savingNewBooking: true,
            showNewBookingErrorModal: false,
        });
        this.state.newBooking
            .store()
            .then((response) => {
                this.notification("success", "New booking added");
                this.setState({
                    savingNewBooking: false,
                    newBooking: this.newEventBooking(),
                    bookingErrors: [],
                });
                this.getBookings();
            })
            .catch((error) => {
                this.notification("error", "Failed to add new booking");
                if (error.response.status === 403) {
                    this.setState({ showNewBookingErrorModal: true });
                }
                // this.getUser(this.state.newBooking.user);
                this.setState({ savingNewBooking: false });
            });
    }

    listAdditionalPlayerSlots() {
        if (typeof this.state.newBooking.user !== "undefined") {
            return (
                <Segment vertical>
                    {this.errorsFor("players")}
                    <label className="with-input">No. of players</label>
                    <NumberInput
                        fluid
                        min={1}
                        placeholder="No. of players"
                        value={this.state.newBooking.players}
                        onChange={(value) =>
                            this.onChangeNewBookingNoOfPlayers(value)
                        }
                        disabled={this.state.event.noGuests}
                    />
                    <div className="mt-3">
                        <p>
                            <small>
                                Please provide the details of any players you
                                know will be attending under this booking
                            </small>
                        </p>
                        <Button
                            primary
                            className="theme"
                            onClick={this.addPlayerDetails.bind(this)}
                        >
                            Add player details
                        </Button>
                    </div>
                    {this.listPlayerDetails()}
                    <div className="mt-3 mb-3 text-right">
                        <Button
                            primary
                            className="theme mt-2"
                            onClick={this.saveNewBooking.bind(this)}
                            loading={this.state.savingNewBooking}
                        >
                            Save booking
                        </Button>
                    </div>
                </Segment>
            );
        }
    }

    addBooking() {
        return (
            <div className="mt-3">
                <h2>Add booking</h2>
                <Segment vertical>
                    {this.errorsFor("user")}
                    <label className="with-input">Member</label>
                    <MemberSearchSelect
                        onChange={this.onChangeMember.bind(this)}
                        value={this.state.newBooking.user}
                        active
                        fluid
                    />
                </Segment>
                {this.listAdditionalPlayerSlots()}
            </div>
        );
    }

    getTotalPlayers() {
        var totalBookings = 0;
        const active = this.state.bookings.filter(
            ({ cancelledAt }) => cancelledAt === undefined
        );
        active.map((booking) => {
            totalBookings += booking.players;
        });

        if (totalBookings === 0) {
            return <span className={styles.playersText}>(0 Players)</span>;
        } else {
            return this.state.event.maxUsers > 0 ? (
                <span className={styles.playersText}>
                    ({totalBookings} {`/ ${this.state.event.maxUsers} players`})
                </span>
            ) : (
                <span className={styles.playersText}>
                    ({totalBookings} players)
                </span>
            );
        }
    }

    update(event) {
        this.setState({ event });
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny">
                    <div className="col">
                        <Tabs defaultActiveKey="details">
                            <Tab eventKey="details" title="Details">
                                {this.eventDetailsTab()}
                            </Tab>
                            <Tab eventKey="bookings" title="Bookings">
                                <div className={styles.mainSegment}>
                                    {this.addBooking()}
                                    <h2>Bookings {this.getTotalPlayers()}</h2>
                                    {this.getBookingTotal()}
                                    {this.bookingsTable()}
                                </div>
                            </Tab>
                            <Tab
                                eventKey="cancelledBookings"
                                title="Cancelled bookings"
                            >
                                <div className={styles.mainSegment}>
                                    <h2>Cancelled bookings</h2>
                                    {this.cancelledBookingsTable()}
                                </div>
                            </Tab>
                            <Tab
                                eventKey="reserveBookings"
                                title="Reserve bookings"
                            >
                                <div className={styles.mainSegment}>
                                    <h2>Reserve bookings</h2>
                                    {this.reservesTable()}
                                </div>
                            </Tab>
                            <Tab eventKey="location" title="Location">
                                <div className={styles.mainSegment}>
                                    <h2>Location</h2>
                                    <Location
                                        event={this.state.event}
                                        update={this.update.bind(this)}
                                        save={this.save.bind(this)}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                    {this.insufficientCreditModal()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
