import React from "react";
import { connect } from "react-redux";
import { Segment, Checkbox } from "semantic-ui-react";

const CourseCreditAvailability = ({ course, onChange }) => {
    const onToggle = (day, time) => {
        const value = course.creditAvailability[day][time] === false;
        course.creditAvailability[day][time] = value;
        const oppositeTime = time === "am" ? "pm" : "am";
        const oppositeValue = course.creditAvailability[day][oppositeTime];
        if (value === false && oppositeValue === false) {
            course.creditAvailability[day][oppositeTime] = true;
        }
        onChange(course);
    };

    return (
        <>
            <Segment vertical>
                <h3>Monday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.monday.am}
                    onChange={() => onToggle("monday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.monday.pm}
                    onChange={() => onToggle("monday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Tuesday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.tuesday.am}
                    onChange={() => onToggle("tuesday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.tuesday.pm}
                    onChange={() => onToggle("tuesday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Wednesday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.wednesday.am}
                    onChange={() => onToggle("wednesday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.wednesday.pm}
                    onChange={() => onToggle("wednesday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Thursday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.thursday.am}
                    onChange={() => onToggle("thursday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.thursday.pm}
                    onChange={() => onToggle("thursday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Friday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.friday.am}
                    onChange={() => onToggle("friday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.friday.pm}
                    onChange={() => onToggle("friday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Saturday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.saturday.am}
                    onChange={() => onToggle("saturday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.saturday.pm}
                    onChange={() => onToggle("saturday", "pm")}
                />
            </Segment>
            <Segment vertical>
                <h3>Sunday</h3>
                <Checkbox
                    toggle
                    label="A.M"
                    checked={course.creditAvailability.sunday.am}
                    onChange={() => onToggle("sunday", "am")}
                />
                <Checkbox
                    className="ml-5"
                    toggle
                    label="P.M"
                    checked={course.creditAvailability.sunday.pm}
                    onChange={() => onToggle("sunday", "pm")}
                />
            </Segment>
        </>
    );
};

function mapStateToProps(state) {
    return {
        roles: state.roles,
    };
}

export default connect(mapStateToProps)(CourseCreditAvailability);
