import BaseModel from "./BaseModel";
import { Label } from "semantic-ui-react";
import PartnerClubContract from "./PartnerClubContract";
const config = require("../config");

class CourseVoucher extends BaseModel {
    static route = "course-vouchers";

    static casts = {
        activatesAt: this.casters.date,
        expiresAt: this.casters.date,
        usedAt: this.casters.date,
        contract: (data) =>
            data === null ? null : new PartnerClubContract(data),
    };

    storePayloadTransformer() {
        return {
            activatesAt: this.activatesAt,
            expiresAt: this.expiresAt,
            course: this.course,
            user: this.user,
            contract: this.contract === null ? null : this.contract._id,
            players: this.players,
        };
    }

    updatePayloadTransformer() {
        return {
            activatesAt: this.activatesAt,
            expiresAt: this.expiresAt,
            players: this.players,
        };
    }

    get statusLabel() {
        if (this.status === "ACTIVE") {
            return <Label color="green">Active</Label>;
        }

        if (this.status === "USED") {
            return <Label color="purple">Used</Label>;
        }

        if (this.status === "EXPIRED") {
            return <Label color="red">Expired</Label>;
        }

        return <Label color="grey">Unknown</Label>;
    }

    static async userIndex(id, params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}users/${id}/${this.route}`,
            params
        );
        return {
            data: data.data.map((item) => new this(item)),
            total: data.total,
        };
    }
}

export default CourseVoucher;
