import NotificationActions from "../store/actions/notification";
import ValidationActions from "../store/actions/validation";

const useDispatchUtilities = (dispatch) => {
    return {
        setNotification: (type, text) => {
            dispatch(
                NotificationActions.create({
                    type,
                    text,
                })
            );
        },
        setValidationErrors: (exception) => {
            if (exception?.response?.status === 422) {
                dispatch(
                    ValidationActions.create(exception.response.data.errors)
                );
            }
        },
        removeValidationErrors: () => {
            dispatch(ValidationActions.remove());
        },
    };
};

export default useDispatchUtilities;
