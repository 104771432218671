import axios from "axios";
import { immerable, produce } from "immer";
const config = require("../config");

export default class BaseModel {
    [immerable] = true;
    static casters = {
        date: (value) => new Date(value),
        number: Number,
        string: String,
        boolean: Boolean,
    };
    static casts = {};
    static defaults = {};
    static route = null;
    static fillable = [];

    constructor(properties = {}) {
        Object.assign(
            this,
            this.constructor.defaults,
            this.castInitialProperties(properties)
        );
        this.stateManager = null;
    }

    castInitialProperties(properties) {
        const casted = {};
        for (const property in properties) {
            if (properties[property] === null) continue;
            const caster = this.constructor.casts[property] ?? null;
            if (typeof caster === "function") {
                casted[property] = this.constructor.casts[property](
                    properties[property]
                );
            }
        }

        return Object.assign(properties, casted);
    }

    getFormData() {
        return [];
    }

    setStateManager(manager) {
        if (this.stateManager === null) {
            this.stateManager = manager;
        }
    }

    useStateManager(mutator) {
        if (this.stateManager === null) {
            throw new Error(
                "State manager is null. Call setStateManager first to set a state manager function."
            );
        }
        this.stateManager(
            produce((draft) => {
                mutator(draft);
                return draft;
            })
        );
    }

    static async index(params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}${this.route}`,
            params
        );
        return {
            data: data.data.map((item) => new this(item)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}${this.route}/list`,
            params
        );
        return data.data.map((item) => new this(item));
    }

    static async show(id, params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}${this.route}/${id}`,
            params
        );
        return new this(data.data);
    }

    async store() {
        const { data } = await this.constructor
            .http()
            .post(
                `${config.api_url}${this.constructor.route}`,
                this.storePayloadTransformer()
            );
        return new this.constructor(data.data);
    }

    static async store(payload = {}) {
        const { data } = await this.http().post(
            `${config.api_url}${this.route}`,
            payload
        );
        return new this(data.data);
    }

    async update() {
        const { data } = await this.constructor
            .http()
            .put(
                `${config.api_url}${this.constructor.route}/${this._id}`,
                this.updatePayloadTransformer()
            );
        return new this.constructor(data.data);
    }

    async delete() {
        await this.constructor
            .http()
            .delete(`${config.api_url}${this.constructor.route}/${this._id}`);
    }

    static http() {
        if (this.route === null) {
            throw new Error("Model route not set");
        }

        return {
            get: this.httpGet,
            post: this.httpPost,
            put: this.httpPut,
            patch: this.httpPatch,
            delete: this.httpDelete,
        };
    }

    static async httpGet(url, params = {}) {
        return await axios.get(url, {
            params,
        });
    }

    static async httpPost(url, payload = {}) {
        return await axios.post(url, payload);
    }

    static async httpPut(url, payload = {}) {
        return await axios.put(url, payload);
    }

    static async httpPatch(url, payload = {}) {
        return await axios.patch(url, payload);
    }

    static async httpDelete(url) {
        return await axios.delete(url);
    }

    static storePayloadTransformer(model) {
        return model;
    }

    static updatePayloadTransformer(model) {
        return model;
    }
}
