import BaseModel from "./BaseModel";

class CourseGroup extends BaseModel {
    static route = "course-groups";

    static casts = {
        createdAt: this.casters.date,
    };

    static defaults = {
        roleBlockList: [],
        entityBlockList: [],
        availability: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        },
    };

    storePayloadTransformer() {
        return {
            name: this.name,
            entityBlockList: this.entityBlockList,
            roleBlockList: this.roleBlockList,
            availability: this.availability,
        };
    }

    updatePayloadTransformer() {
        return {
            name: this.name,
            entityBlockList: this.entityBlockList,
            roleBlockList: this.roleBlockList,
            availability: this.availability,
        };
    }
}

export default CourseGroup;
