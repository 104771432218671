import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import CourseGroup from "../../models/CourseGroup";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Details from "../../assets/components/courseGroup/Details";
import Availability from "../../assets/components/courseGroup/Availability";
import Role from "../../models/Role";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import CenterLoader from "../../assets/components/CenterLoader";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: true,
            loadingRoles: true,
            error: null,
            group: new CourseGroup(),
            calendarBookings: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getGroup();
        this.getRoles();
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getGroup() {
        CourseGroup.show(this.props.match.params.id)
            .then((group) => {
                this.setState({ group, loading: false });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loading: false,
                });
                this.notification(
                    "error",
                    "Failed to load group. Please try again."
                );
            });
    }

    getRoles() {
        Role.index()
            .then(({ data }) => {
                const exclude = [
                    "MASHIE_ADMIN",
                    "ENTITY_ADMIN",
                    "APP_USER",
                    "ENTITY_CONTACT",
                ];
                const roles = data.filter(
                    (role) => exclude.includes(role.key) === false
                );
                this.setState({ loadingRoles: false, roles });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification("error", "Failed to user, please try again");
            });
    }

    displayError() {
        if (this.state.error === 404) {
            return <Partial404 />;
        }

        return <Partial500 />;
    }

    update(group) {
        this.setState({ group });
    }

    save() {
        this.setState({ saving: true });
        this.state.group
            .update()
            .then((response) => {
                this.notification("success", "Group created successfully");
                this.setState({ saving: false });
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to update group");
                this.handleValidationErrors(error);
            });
    }

    loaded() {
        return (
            this.state.loadingRoles === false && this.state.loading === false
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container skinny">
                <h2>Course Group</h2>
                <div className="row">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                <h3 className="mt-3">Group details</h3>
                                <p>Set the group's details</p>
                                <Details
                                    roles={this.state.roles}
                                    update={this.update.bind(this)}
                                    group={this.state.group}
                                    save={this.save.bind(this)}
                                    saving={this.state.saving}
                                />
                            </Tab>
                            <Tab eventKey="availability" title="Availability">
                                <h3 className="mt-3">
                                    Course group availability
                                </h3>
                                <p>
                                    Set how many bookings can be made within
                                    this group per day{" "}
                                </p>
                                <Availability
                                    update={this.update.bind(this)}
                                    group={this.state.group}
                                    save={this.save.bind(this)}
                                    saving={this.state.saving}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
