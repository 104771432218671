import React, { Component } from "react";
import {
    Input,
    Table,
    Segment,
    Form,
    Checkbox,
    TextArea,
    Button,
} from "semantic-ui-react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import NumberInput from "../../../assets/components/NumberInput";
import CourseCreditPricing from "../../../assets/components/golfClub/CourseCreditPricing";
import CourseCreditAvailability from "../../../assets/components/golfClub/CourseCreditAvailability";
import GolfCourse from "../../../models/GolfCourse";
import PartnerClubContract from "../../../models/PartnerClubContract";
import GroupSearchSelect from "../GroupSearchSelect";

class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: null,
            saving: false,
            editorOpen: false,
        };

        this.daysOfWeek = [
            {
                key: 0,
                value: "monday",
                text: "Monday",
            },
            {
                key: 1,
                value: "tuesday",
                text: "Tuesday",
            },
            {
                key: 2,
                value: "wednesday",
                text: "Wednesday",
            },
            {
                key: 3,
                value: "thursday",
                text: "Thursday",
            },
            {
                key: 4,
                value: "friday",
                text: "Friday",
            },
            {
                key: 5,
                value: "saturday",
                text: "Saturday",
            },
            {
                key: 6,
                value: "sunday",
                text: "Sunday",
            },
        ];

        this.seasons = [
            {
                key: 0,
                value: "winter",
                text: "Winter",
            },
            {
                key: 1,
                value: "summer",
                text: "Summer",
            },
        ];

        this.times = [
            {
                key: 0,
                value: "am",
                text: "AM",
            },
            {
                key: 1,
                value: "pm",
                text: "PM",
            },
        ];
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
        this.props.dispatch(ValidationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    gatherInput(property, value) {
        const { editing } = this.state;
        editing[property] = value;
        this.setState({ editing });
    }

    deleteCourse(index) {
        const confirm = window.confirm(
            "Are you sure you want to delete this course?"
        );
        if (confirm === true) {
            const { club } = this.props;
            const course = club.courses[index];
            course
                .delete()
                .then((result) => {
                    this.setState({ editing: null });
                    this.notification("success", "Course deleted");
                    club.courses.splice(index, 1);
                    this.props.update(club);
                })
                .catch((error) => {
                    this.notification("success", "Faield to delete course");
                });
        }
    }

    listCourses() {
        if (this.state.editing !== null) {
            return;
        }

        if (this.props.club.courses.length === 0) {
            return <p className="text-center">This club has no courses yet.</p>;
        }
        return this.props.club.courses.map((course, index) => {
            return (
                <Segment vertical key={index}>
                    <div className="row">
                        <div className="col-10">
                            <h4 className="mt-2">{course.name}</h4>
                            <p className="m-0">
                                <b>Par:</b> {course.par}
                            </p>
                            <p className="m-0">
                                <b>Holes:</b> {course.holes}
                            </p>
                            <p className="m-0">
                                <b>Yardage:</b> {course.yardage}
                            </p>
                            <p className="m-0">
                                <b>Description:</b> {course.description}
                            </p>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                            <Button
                                primary
                                className="theme"
                                onClick={() =>
                                    this.setState({ editing: course })
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                primary
                                className="red"
                                onClick={() => this.deleteCourse(index)}
                                value={index}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Segment>
            );
        });
    }

    cancelEditor() {
        this.setState({ editing: null });
        this.props.dispatch(ValidationActions.remove());
    }

    onChangeGreenFee(season, day, membership, time, value) {
        const { editing } = this.state;
        editing.greenFees[season][day][membership][time] = value;
        this.setState({ editing });
    }

    onChangeHighest(season, value) {
        const { editing } = this.state;
        editing.greenFees[season].highest = value;
        this.setState({ editing });
    }

    onChangePoa(season, checked) {
        const { editing } = this.state;
        editing.greenFees[season].poa = checked;
        this.setState({ editing });
    }

    onToggle(property) {
        const { editing } = this.state;
        editing[property] = editing[property] === false;
        this.setState({ editing });
    }

    addCourseButton() {
        if (this.state.editing !== null) {
            return;
        }

        return (
            <Segment vertical>
                <Button
                    primary
                    className="theme"
                    onClick={() => this.setState({ editing: new GolfCourse() })}
                >
                    Add course
                </Button>
            </Segment>
        );
    }

    showCorrectCurrency() {
        return this.props.club?.currency?.icon ?? "";
    }

    courseEditor() {
        if (this.state.editing === null) {
            return;
        }

        return (
            <>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Course name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.editing.name}
                        onChange={({ target }) =>
                            this.gatherInput("name", target.value)
                        }
                        placeholder="Name"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("par")}
                    <label className="with-input">Par</label>
                    <NumberInput
                        fluid
                        name="par"
                        value={this.state.editing.par}
                        onChange={(value) => this.gatherInput("par", value)}
                        placeholder="Par"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("holes")}
                    <label className="with-input">Holes</label>
                    <NumberInput
                        fluid
                        name="holes"
                        value={this.state.editing.holes}
                        onChange={(value) => this.gatherInput("holes", value)}
                        placeholder="Holes"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("yardage")}
                    <label className="with-input">Yardage</label>
                    <NumberInput
                        fluid
                        name="yardage"
                        value={this.state.editing.yardage}
                        onChange={(value) => this.gatherInput("yardage", value)}
                        placeholder="Yardage"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("description")}
                    <label className="with-input">Description</label>
                    <Form>
                        <TextArea
                            name="description"
                            value={this.state.editing.description}
                            onChange={({ target }) =>
                                this.gatherInput("description", target.value)
                            }
                            placeholder="Decription"
                        />
                    </Form>
                </Segment>
                <h2>Tee time pricing</h2>
                <p>
                    Allocate a price to each time slot. If no price is provided,
                    the time slot will not be bookable. You will not be able to
                    enter "0" as a price. If you wish to prevent the slot from
                    being booked, do not enter a value.
                </p>
                <h3>Summer</h3>
                <Checkbox
                    toggle
                    label="POA"
                    checked={this.state.editing.greenFees.summer.poa}
                    onChange={(_, { checked }) =>
                        this.onChangePoa("summer", checked)
                    }
                />
                <p>
                    <small>
                        If POA is selected, all prices for this season will
                        appear as "POA" within the MASHIE app
                    </small>
                </p>
                <label className="with-input">Highest summer price</label>
                <NumberInput
                    decimal
                    icon={this.showCorrectCurrency()}
                    nullable
                    min={1}
                    iconPosition="left"
                    name="summer"
                    onChange={(value) => this.onChangeHighest("summer", value)}
                    value={this.state.editing.greenFees.summer.highest ?? ""}
                    placeholder="Highest summer price"
                />
                <Table columns={8}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Monday</Table.HeaderCell>
                            <Table.HeaderCell>Tuesday</Table.HeaderCell>
                            <Table.HeaderCell>Wednesday</Table.HeaderCell>
                            <Table.HeaderCell>thursday</Table.HeaderCell>
                            <Table.HeaderCell>Friday</Table.HeaderCell>
                            <Table.HeaderCell>Saturday</Table.HeaderCell>
                            <Table.HeaderCell>Sunday</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Country)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Links)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Club)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Country)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Links)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Club)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .monday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "monday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .tuesday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "tuesday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .wednesday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "wednesday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .thursday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "thursday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .friday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "friday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .saturday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "saturday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.summer
                                            .sunday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "summer",
                                            "sunday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <h3>Winter</h3>
                <Checkbox
                    toggle
                    label="POA"
                    checked={this.state.editing.greenFees.winter.poa}
                    onChange={(_, { checked }) =>
                        this.onChangePoa("winter", checked)
                    }
                />
                <p>
                    <small>
                        If POA is selected, all prices for this season will
                        appear as "POA" within the MASHIE app
                    </small>
                </p>
                <label className="with-input">Highest winter price</label>
                <NumberInput
                    decimal
                    icon={this.showCorrectCurrency()}
                    nullable
                    min={1}
                    iconPosition="left"
                    name="winter"
                    onChange={(value) => this.onChangeHighest("winter", value)}
                    value={this.state.editing.greenFees.winter.highest ?? ""}
                    placeholder="Highest winter price"
                />
                <Table columns={8}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Monday</Table.HeaderCell>
                            <Table.HeaderCell>Tuesday</Table.HeaderCell>
                            <Table.HeaderCell>Wednesday</Table.HeaderCell>
                            <Table.HeaderCell>thursday</Table.HeaderCell>
                            <Table.HeaderCell>Friday</Table.HeaderCell>
                            <Table.HeaderCell>Saturday</Table.HeaderCell>
                            <Table.HeaderCell>Sunday</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Country)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.country.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "country",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Links)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.links.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "links",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>A.M (Club)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.club.am ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "club",
                                            "am",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Country)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.country.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "country",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Links)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.links.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "links",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>P.M (Club)</b>
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="monday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .monday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "monday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="tuesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .tuesday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "tuesday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="wednesday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .wednesday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "wednesday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="thursday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .thursday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "thursday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="friday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .friday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "friday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="saturday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .saturday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "saturday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberInput
                                    icon={this.showCorrectCurrency()}
                                    nullable
                                    min={1}
                                    name="sunday"
                                    value={
                                        this.state.editing.greenFees.winter
                                            .sunday.club.pm ?? ""
                                    }
                                    onChange={(value) =>
                                        this.onChangeGreenFee(
                                            "winter",
                                            "sunday",
                                            "club",
                                            "pm",
                                            value
                                        )
                                    }
                                    fluid
                                    iconPosition="left"
                                    decimal
                                    placeholder="Price"
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Segment vertical>
                    <h3>Corporate group</h3>
                    <p>
                        Courses which accept credit bookings must be attached to
                        a corporate group. Set a group to edit credit pricing.
                    </p>

                    <GroupSearchSelect
                        placeholder="Select a group"
                        value={this.state.editing.group ?? null}
                        onChange={(value) => {
                            this.state.editing.group = value;
                            this.setState({
                                editing: this.state.editing,
                            });
                        }}
                    />
                </Segment>

                {this.state.editing.group && (
                    <>
                        {this.state.editing.contracts.length === 0 ? (
                            <>
                                <p className="mt-3">
                                    <b>
                                        This course does not currently have any
                                        contracts. Add a contract to configure
                                        credit pricing
                                    </b>
                                </p>
                                <Link to="/partner-club-contracts/create">
                                    <Button primary className="theme">
                                        Create contract
                                    </Button>
                                </Link>
                            </>
                        ) : (
                            <>
                                <Segment vertical>
                                    <h3>Contracts</h3>
                                    {this.state.editing.contracts.map(
                                        (item) => {
                                            const contract =
                                                new PartnerClubContract(item);
                                            return (
                                                <div className="mt-3">
                                                    <Link
                                                        className="mr-2"
                                                        to={`/partner-club-contracts/${contract._id}`}
                                                    >
                                                        {contract.name}
                                                    </Link>
                                                    {contract.statusLabel}
                                                    <span className="ml-3">
                                                        Activation:{" "}
                                                        {format(
                                                            item.activatesAt,
                                                            "dd/MM/yyyy"
                                                        )}
                                                    </span>
                                                    <span className="ml-3">
                                                        Expiry:{" "}
                                                        {format(
                                                            item.expiresAt,
                                                            "dd/MM/yyyy"
                                                        )}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    )}
                                </Segment>
                                <h2>Credit pricing</h2>
                                <p>Configure credit pricing for this course.</p>
                                <Checkbox
                                    toggle
                                    label="Accepts credit bookings"
                                    checked={this.state.editing.acceptsCredit}
                                    onChange={() =>
                                        this.onToggle("acceptsCredit")
                                    }
                                />
                                <CourseCreditPricing
                                    roles={[]}
                                    course={this.state.editing}
                                    onChange={(val) =>
                                        this.setState({ editing: val })
                                    }
                                />
                                <h2>Credit availability</h2>
                                <p>
                                    Configure credit availability for this
                                    course.
                                </p>
                                <CourseCreditAvailability
                                    course={this.state.editing}
                                    onChange={(val) =>
                                        this.setState({ editing: val })
                                    }
                                />
                            </>
                        )}
                    </>
                )}
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        onClick={this.confirmCourse.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                    <Button onClick={this.cancelEditor.bind(this)}>
                        Cancel
                    </Button>
                </Segment>
            </>
        );
    }

    confirmCourse() {
        const { club } = this.props;
        const { editing } = this.state;
        this.setState({ saving: true });

        if (typeof editing._id === "undefined") {
            editing
                .store(this.props.club._id)
                .then((result) => {
                    const course = new GolfCourse(result.data.data);
                    club.courses.push(course);
                    this.setState({ editing: null, saving: false });
                    this.notification("success", "Course saved");
                    this.props.update(club);
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    this.notification(
                        "error",
                        "Failed to save course, please try again"
                    );
                    this.handleValidationErrors(error);
                });
        } else {
            const index = club.courses.findIndex(
                (course) => course._id === editing._id
            );
            editing
                .update(this.props.club._id)
                .then((result) => {
                    const course = new GolfCourse(result.data.data);
                    club.courses.splice(index, 1, course);
                    this.setState({ editing: null, saving: false });
                    this.notification("success", "Course updated");
                    this.props.update(club);
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    this.notification(
                        "error",
                        "Failed to save course, please try again"
                    );
                    this.handleValidationErrors(error);
                });
        }
    }

    render() {
        return (
            <>
                {this.courseEditor()}
                {this.listCourses()}
                {this.addCourseButton()}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Courses);
