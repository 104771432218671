import React from 'react'
import styles from '../style.module.scss'

export default function SmallHeader({bottom = false}) {
  return (
	<div className={styles.smallHeader} style={bottom === true ? {position: 'fixed', bottom: 0} : {}}>
	<a
	  href="https://mashiegolf.co.uk/"
	  target="_blank"
	  rel="noopener noreferrer"
	>
	  HOME
	</a>
	<a
	  href="https://mashieclub.co.uk/"
	  target="_blank"
	  rel="noopener noreferrer"
	>
	  CLUB
	</a>
	<a
	  href="https://mashieevents.co.uk/"
	  target="_blank"
	  rel="noopener noreferrer"
	>
	  EVENTS
	</a>
	<a
	  href="https://mashieclothing.co.uk/"
	  target="_blank"
	  rel="noopener noreferrer"
	>
	  CLOTHING
	</a>
  </div>
  )
}
