import React, { Component } from "react";
import { Input } from "semantic-ui-react";

class NumberInput extends Component {
    parse(value) {
        const negative = this.props.negative ?? false;
        const decimal = this.props.decimal ?? false;

        if (value === null) return "";
        if (
            value === "-" ||
            value === "" ||
            value.lastIndexOf(".") === value.length - 1
        ) {
            return value;
        }

        const asFloat = parseFloat(value);
        if (isNaN(asFloat)) return "";

        if (negative === false) {
            if (asFloat < 0) {
                return 0 - asFloat;
            }
        }
        if (decimal === false) {
            return parseInt(asFloat);
        }

        return asFloat;
    }

    onBlur(value) {
        const interval = this.props.interval ?? null;
        const min = this.props.min ?? null;
        const max = this.props.max ?? null;

        if (min !== null && max !== null && min > max) {
            throw new Error("Min must be greater than max");
        }

        if (interval !== null) {
            if (interval < 1) {
                throw new Error(
                    interval +
                        " is not a valid interval: must be greater than 0"
                );
            }

            if (max !== null && interval > max) {
                throw new Error(
                    interval + " is not a valid interval: must be less than max"
                );
            }
        }

        // work out if there is a valid interval between the min and max.
        if (interval !== null && min !== null && max !== null) {
            let valid = false;
            const factor = min % interval;

            if (interval >= min && interval <= max) {
                valid = true;
            }

            const inRange = min + (interval - factor);
            if ((inRange >= min && inRange <= max) || factor === 0) {
                valid = true;
            }

            if (valid === false) {
                throw new Error(
                    interval +
                        " is not a valid interval: no factor available between min and max"
                );
            }
        }

        let parsed = this.parse(value);

        if (parsed === "" && this.props.nullable === true) {
            return this.props.onChange(null);
        }

        if (isNaN(parseFloat(parsed))) {
            parsed = 0;
        }

        this.props.onChange(
            this.roundToNearestInterval(parsed, min, max, interval)
        );
    }

    roundToNearestInterval(value, min = null, max = null, interval = null) {
        if (interval !== null) {
            value = Math.round(value / interval) * interval;
        }

        if (min !== null && value < min) {
            return min;
        }

        if (max !== null && value > max) {
            return max;
        }

        return value;
    }

    render() {
        const allowedProps = [
            "key",
            "fluid",
            "value",
            "className",
            "icon",
            "iconPosition",
            "name",
            "disabled",
        ];
        const filteredProps = [];
        for (const key in this.props) {
            if (allowedProps.includes(key)) {
                filteredProps[key] = this.props[key];
            }
        }

        return (
            <Input
                onBlur={({ target }) => this.onBlur(target.value)}
                placeholder={this.props.placeholder ?? ""}
                onChange={({ target }) => {
                    this.props.onChange(this.parse(target.value));
                }}
                {...filteredProps}
            />
        );
    }
}

export default NumberInput;
