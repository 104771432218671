import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "../../../assets/components/dataTable";
import { Button } from "semantic-ui-react";
import { format } from "date-fns";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import LoadingPlaceholder from "../../../assets/components/LoadingPlaceholder";

const Usage = ({ contract }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [table, setTable] = useState({
        page: 1,
        term: "",
        sort: "createdAt",
        order: "desc",
        limit: 1000,
    });
    const [filters, setFilters] = useState({
        member: null,
    });

    const fetchData = async (params) => {
        const { data } = await contract.usage(params);
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData(table);
    }, []);

    const onChangeFilter = (property, value) => {
        filters[property] = value;
        table.page = 1;
        setFilters(filters);
        setTable(table);
        fetchData(Object.assign({}, table, filters));
    };

    const headers = [
        { key: "type", text: "Type" },
        { key: "user", text: "Member" },
        { key: "course", text: "Course" },
        { key: "usedAt", text: "Date created" },
        { key: "actions", text: "Actions" },
    ];

    if (loading === true) {
        return <LoadingPlaceholder />;
    }

    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    {contract.used !== data.length && (
                        <p>
                            <b>
                                NOTE: The quantity used on the contract does not
                                equal the number of records shown below. This is
                                likely because of ad-hoc usage by MASHIE staff
                                against events bookings.
                            </b>
                        </p>
                    )}
                    <MemberSearchSelect
                        placeholder="Filter by member"
                        value={filters.member}
                        onChange={(value) => onChangeFilter("member", value)}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h3>Uses</h3>
                    <DataTable
                        loading={loading}
                        headers={headers}
                        data={data.map((item) => {
                            return {
                                type: {
                                    value: item.type,
                                },
                                user: {
                                    value: item.user.name,
                                },
                                course: {
                                    value: (
                                        <Link
                                            to={`/golf-clubs/${item.course.club._id}`}
                                        >
                                            {item.course.longName}
                                        </Link>
                                    ),
                                },
                                usedAt: {
                                    value: format(
                                        new Date(item.usedAt),
                                        "dd/MM/yyyy"
                                    ),
                                },

                                actions: {
                                    value: (
                                        <Link
                                            to={
                                                item.type === "TEETIME"
                                                    ? "/tee-time-requests/"
                                                    : "/course-vouchers/" +
                                                      item._id
                                            }
                                        >
                                            <Button primary className="theme">
                                                View
                                            </Button>
                                        </Link>
                                    ),
                                },
                            };
                        })}
                        totalRecords={data.length}
                        onChange={fetchData}
                        sort={table.sort}
                        hideSearch
                        order={table.order}
                        limit={table.limit}
                        sortOptions={[
                            { text: "Date created", key: "createdAt" },
                        ]}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h3>Adjustments</h3>
                    <DataTable
                        loading={loading}
                        headers={[
                            { key: "adjustment", text: "Adjustment" },
                            { key: "reason", text: "Reason" },
                            { key: "createdBy", text: "Created by" },
                            { key: "createdAt", text: "Date created" },
                        ]}
                        data={contract.adjustments.map((item) => {
                            return {
                                adjustment: {
                                    value: `${item.adjustment > 0 ? "+" : ""}${
                                        item.adjustment
                                    }`,
                                },
                                reason: {
                                    value: item.reason,
                                },
                                createdBy: {
                                    value: item.createdBy.name,
                                },
                                createdAt: {
                                    value: format(
                                        new Date(item.createdAt),
                                        "dd/MM/yyyy"
                                    ),
                                },
                            };
                        })}
                        totalRecords={contract.adjustments.length}
                        hideSearch
                    />
                </div>
            </div>
        </>
    );
};

export default Usage;
