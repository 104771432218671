import React, { Component } from "react";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "./menu";
import UserStoreActions from "../../store/actions/user";
import User from "../../models/User";
import logo from "../images/mashie-shield-with-text.png";
import profileImagePlaceholder from "../../assets/images/user-tie.svg";
import { Input, Segment } from "semantic-ui-react";
import axios from "axios";
const config = require("../../config");

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }

    toggleMenu() {
        this.setState({ menu: !this.state.menu });
    }

    logout() {
        this.props.user
            .logout()
            .then((response) => {
                this.props.dispatch(UserStoreActions.logOutUser());
                this.props.history.push("/auth/login");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    search(event) {
        if (event.keyCode === 13) {
            this.props.history.push("/search/" + encodeURI(event.target.value));
        }
    }

    profileImage() {
        if (typeof this.props.user.profile_image !== "undefined") {
            return (
                config.content_url + "profiles/" + this.props.user.profile_image
            );
        }

        return profileImagePlaceholder;
    }

    links() {
        if (this.props.user.isAuthenticated() === true) {
            if (this.props.user.has("MASHIE_ADMIN") === false) {
                return (
                    <ul className={styles.list}>
                        <h3>
                            {this.props.user.currentAdministrationEntity.name}
                        </h3>
                        <li>
                            <Link to="/members">Members</Link>
                        </li>
                        <li>
                            <Link to="/admins">Admins</Link>
                        </li>
                        <li>
                            <Link to="/tee-time-requests">
                                Tee time requests
                            </Link>
                        </li>
                        <ul>
                            <li>
                                <Link to="/tee-time-requests/create">
                                    Create
                                </Link>
                            </li>
                        </ul>
                        <h3>Your account</h3>
                        <li>
                            <Link to="/settings">Settings</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={this.logout.bind(this)}>
                                Logout
                            </Link>
                        </li>
                    </ul>
                );
            }

            return (
                <ul className={styles.list}>
                    <h3>{this.props.user.currentAdministrationEntity.name}</h3>
                    <li>
                        <Link to="/members">Members</Link>
                    </li>
                    <li>
                        <Link to="/admins">Admins</Link>
                    </li>
                    <h3>Admin Controls</h3>
                    <li>
                        <Link to="/entities">Entities</Link>
                    </li>
                    <li>
                        <Link to="/golf-clubs">Golf clubs</Link>
                    </li>
                    <li>
                        <Link to="/tee-time-requests">Tee time requests</Link>
                    </li>
                    <ul>
                        <li>
                            <Link to="/tee-time-requests/create">Create</Link>
                        </li>
                    </ul>
                    <li>
                        <Link to="/transactions">Credit requests</Link>
                    </li>
                    <li>
                        <Link to="/purchase-transactions">App purchases</Link>
                    </li>
                    <li>
                        <Link to="/events">Events</Link>
                    </li>
                    <ul>
                        <li>
                            <Link to="/events/bookings">Bookings</Link>
                        </li>
                        <li>
                            <Link to="/events/reserves">Reserves</Link>
                        </li>
                        <li>
                            <Link to="/events/series">Series</Link>
                        </li>
                    </ul>
                    <li>
                        <Link to="/offers">Partner offers</Link>
                    </li>
                    <li>
                        <Link to="#">Reviews</Link>
                    </li>
                    <ul>
                        <li>
                            <Link to="/reviews/clubs">Club reviews</Link>
                        </li>
                        <li>
                            <Link to="/reviews/bookings">Booking reviews</Link>
                        </li>
                    </ul>
                    <li>
                        <Link to="/course-groups">Course groups</Link>
                    </li>
                    <li>
                        <Link to="/partner-club-contracts">
                            Partner club contracts
                        </Link>
                    </li>
                    <li>
                        <Link to="/course-vouchers">Course vouchers</Link>
                    </li>
                    <li>
                        <Link to="/mashie-admins">Mashie admins</Link>
                    </li>
                    <li>
                        <Link to="/communications">Communications</Link>
                    </li>
                    <h3>System</h3>
                    <li>
                        <Link to="/reports">Reports</Link>
                    </li>
                    <li>
                        <Link to="/currencies">Currencies</Link>
                    </li>
                    <li>
                        <Link to="/app-variables">App variables</Link>
                    </li>
                    <h3>Your account</h3>
                    <li>
                        <Link to="/settings">Settings</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={this.logout.bind(this)}>
                            Logout
                        </Link>
                    </li>
                </ul>
            );
        }

        return (
            <>
                <ul className={styles.list}>
                    <li>
                        <Link to="/auth/login">Sign in</Link>
                    </li>
                </ul>
            </>
        );
    }

    render() {
        return (
            <header className={styles.header + " d-none d-sm-block"}>
                <div className={styles.content}>
                    <Menu show={this.state.menu} history={this.props.history} />
                    <div className="container-fluid">
                        <div className="row no-gutters">
                            <div className="col">
                                <Link to="/members">
                                    <img
                                        className={styles.logo}
                                        alt="Mashie logo"
                                        src={logo}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col">{this.links()}</div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps)(Header);
