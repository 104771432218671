class ValidationAction {
    static create(data) {
        return { type: "SET_VALIDATION", payload: data };
    }

    static remove() {
        return { type: "CLEAR_VALIDATION" };
    }
}

export default ValidationAction;
