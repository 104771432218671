import React, { Component } from "react";
import DataTable from "../dataTable";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import {
    Button,
    Form,
    Label,
    TextArea,
    Select,
    Segment,
    Modal,
    Checkbox,
} from "semantic-ui-react";
import { Tabs, Tab } from "react-bootstrap";
import NumberInput from "../../../assets/components/NumberInput";
import styles from "../confirmationBox.module.scss";
import AppVariable from "../../../models/AppVariable";
import NotificationActions from "../../../store/actions/notification";
import transactionStyles from "./transaction.module.scss";

class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTransaction: null,
            showConfirmationModal: false,
            denominations: [],
            errorText: "",
            denominationSelection: 0,
        };

        this.statusColours = {
            additional: "#007ab5",
            included: "#ffc53b",
            unused: "#00b5ad",
            used: "#7d2525",
            paid: "#00b5ad",
            unpaid: "#7d2525",
            free: "#00b5ad",
            expired: "#007ab5",
        };
    }

    async onSaveTransaction() {
        try {
            await this.props.save(this.state.newTransaction);
            this.setState({
                showConfirmationModal: false,
                errorText: "",
                newTransaction: null,
                denominationSelection: 0,
            });
            this.props.dispatch(
                NotificationActions.create({
                    type: "success",
                    text: "Credit added successfully",
                })
            );
        } catch (_) {
            this.props.dispatch(
                NotificationActions.create({
                    type: "error",
                    text: "Something went wrong adding your credit",
                })
            );
        }
    }

    eventConfirmation() {
        const isPositive = this.state.newTransaction.amount > 0;
        const { amount, freeAmount } = this.state.newTransaction;
        const total = amount + freeAmount + this.props.user.eventCreditBalance;
        return (
            <div className="row">
                <div className="col text-center">
                    <h3>Current event credit balance</h3>
                    <h3 className={styles.confirmationBoxTextRed}>
                        £{this.props.user.eventCreditBalance.toFixed(2)}
                    </h3>
                </div>
                <div className="col text-center">
                    <h3>Transaction</h3>
                    <h3
                        className={
                            isPositive
                                ? styles.confirmationBoxTextGreen
                                : styles.confirmationBoxTextRed
                        }
                    >
                        {isPositive ? "+" : ""} £
                        {this.state.newTransaction.amount}
                        <br />
                        {this.state.newTransaction.freeAmount > 0 &&
                            `+ ${this.state.newTransaction.freeAmount} free`}
                    </h3>
                </div>
                <div className="col text-center">
                    <h3>&nbsp;</h3>
                    <h3 className={styles.confirmationBoxTextBlack}>=</h3>
                </div>
                <div className="col text-center">
                    <h3>New event credit balance</h3>
                    <h3 className={styles.confirmationBoxTextBlack}>
                        £ {parseFloat(total).toFixed(2)}
                    </h3>
                </div>
            </div>
        );
    }

    teeTimeConfirmation() {
        const isPositive = this.state.newTransaction.amount > 0;
        const { amount } = this.state.newTransaction;
        const total = amount + this.props.user.teeTimeCreditBalance;
        return (
            <div className="row">
                <div className="col text-center">
                    <h3>Current teetime credit balance</h3>
                    <h3 className={styles.confirmationBoxTextRed}>
                        £{this.props.user.teeTimeCreditBalance.toFixed(2)}
                    </h3>
                </div>
                <div className="col text-center">
                    <h3>Transaction</h3>
                    <h3
                        className={
                            isPositive
                                ? styles.confirmationBoxTextGreen
                                : styles.confirmationBoxTextRed
                        }
                    >
                        {isPositive ? "+" : ""} £
                        {this.state.newTransaction.amount}
                    </h3>
                </div>
                <div className="col text-center">
                    <h3>&nbsp;</h3>
                    <h3 className={styles.confirmationBoxTextBlack}>=</h3>
                </div>
                <div className="col text-center">
                    <h3>New teetime credit balance</h3>
                    <h3 className={styles.confirmationBoxTextBlack}>
                        £ {parseFloat(total).toFixed(2)}
                    </h3>
                </div>
            </div>
        );
    }

    renderModal() {
        return (
            <Modal open={this.state.showConfirmationModal}>
                <Modal.Header>Please confirm the below changes</Modal.Header>
                <Modal.Content>
                    {this.state.newTransaction.transactionType === "event"
                        ? this.eventConfirmation()
                        : this.teeTimeConfirmation()}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Cancel"
                        onClick={() =>
                            this.setState({ showConfirmationModal: false })
                        }
                    />
                    <Button
                        primary
                        className="theme"
                        content="Confirm"
                        onClick={() => this.onSaveTransaction()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    onChangeAmount(value) {
        const { newTransaction } = this.state;
        newTransaction.amount = value;
        newTransaction.freeAmount = 0;
        this.setState({ newTransaction, denominationSelection: 0 });
    }

    onChangeNote(event) {
        const { newTransaction } = this.state;
        newTransaction.note = event.target.value;
        this.setState({ newTransaction });
    }

    editorOrTable() {
        if (this.state.newTransaction !== null) {
            return this.transactionEditor();
        }

        return (
            <div className="row mt-3">
                <div className="col">
                    <Tabs defaultActiveKey="eventTransactions">
                        <Tab eventKey="eventTransactions" title="Event credit">
                            {this.props.user?.currentMembership?.key ===
                            "MASHIE_COUNTRY_MEMBERSHIP" ? (
                                <p className="mt-3">
                                    {`${this.props.user.name} is a country member. You are therefore not able to add any new credit transactions to their account.`}
                                </p>
                            ) : (
                                <Button
                                    primary
                                    className="theme mt-3 mb-3"
                                    onClick={() => this.newTransaction("event")}
                                >
                                    New transaction
                                </Button>
                            )}
                            {this.eventTransactionsTable()}
                        </Tab>
                        <Tab
                            eventKey="teeTimeTransactions"
                            title="Teetime credit"
                        >
                            {this.props.user?.currentMembership?.key ===
                            "MASHIE_COUNTRY_MEMBERSHIP" ? (
                                <p className="mt-3">
                                    {`${this.props.user.name} is a country member. You are therefore not able to add any new credit transactions to their account.`}
                                </p>
                            ) : (
                                <Button
                                    primary
                                    className="theme mt-3 mb-3"
                                    onClick={() =>
                                        this.newTransaction("teetime")
                                    }
                                >
                                    New transaction
                                </Button>
                            )}
                            {this.teeTimeTransactionsTable()}
                        </Tab>
                        <Tab eventKey="vouchers" title="Vouchers">
                            <div className="pt-3">{this.vouchersTable()}</div>
                        </Tab>
                        <Tab
                            eventKey="clothingTransactions"
                            title="Clothing credit (legacy)"
                        >
                            <div className="pt-3">
                                {this.clothingTransactionsTable()}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }

    newTransaction(transactionType) {
        this.setState({
            newTransaction: {
                transactionType,
                amount: 0,
                freeAmount: 0,
                note: "",
                isInvoiceable: true,
            },
        });
    }

    cancelNewTransaction() {
        this.setState({
            newTransaction: null,
            showConfirmationModal: false,
            denominationSelection: 0,
        });
    }

    getDenominationsAppVariable() {
        this.setState({ loading: true });
        AppVariable.getByIdOrKey("EVENT_CREDIT_TOPUP_VALUES").then(
            (appVariable) => {
                this.setState({
                    loading: false,
                    denominations: JSON.parse(appVariable.data).options,
                });
            }
        );
    }

    onChangeSelect(value) {
        const { newTransaction } = this.state;
        newTransaction.amount = value;
        for (const amount of this.state.denominations) {
            if (amount.cost === value) {
                newTransaction.freeAmount = amount.free;
            }
        }
        newTransaction.amount += newTransaction.freeAmount;
        this.setState({ newTransaction, denominationSelection: value });
    }

    denominationOptions() {
        const options = this.state.denominations.map((item) => {
            const option = {
                value: item.cost,
                text: "£" + item.cost,
                free: item.free,
            };

            if (item.free > 0) {
                option.text += " + £" + item.free + " free";
            }

            return option;
        });

        options.unshift({ value: 0, text: "Select amount", free: 0 });
        return options;
    }

    componentDidMount() {
        this.getDenominationsAppVariable();
    }

    onChangeIsInvoiceable() {
        const { newTransaction } = this.state;
        newTransaction.isInvoiceable = !newTransaction.isInvoiceable;
        this.setState({ newTransaction });
    }

    eventEditor() {
        return (
            <Segment vertical>
                <label className="with-input">Amount</label>
                <div className={transactionStyles.transactionContainer}>
                    <div className={transactionStyles.transactionWrapper}>
                        <NumberInput
                            negative
                            decimal
                            icon="gbp"
                            iconPosition="left"
                            value={this.state.newTransaction.amount}
                            placeholder="Amount"
                            onChange={(value) => this.onChangeAmount(value)}
                        />
                    </div>
                    <span className={transactionStyles.transactionSpan}>
                        or
                    </span>
                    <div className={transactionStyles.transactionWrapper}>
                        <Select
                            fluid
                            value={this.state.denominationSelection}
                            options={this.denominationOptions()}
                            placeholder="Select amount"
                            onChange={(_, { value }) =>
                                this.onChangeSelect(value)
                            }
                        />
                    </div>
                </div>
                {this.state.errorText !== "" && (
                    <p className={transactionStyles.transactionError}>
                        {this.state.errorText}
                    </p>
                )}
            </Segment>
        );
    }
    teeTimeEditor() {
        return (
            <Segment vertical>
                <label className="with-input">Amount</label>
                <div className={transactionStyles.transactionContainer}>
                    <div className={transactionStyles.transactionWrapper}>
                        <NumberInput
                            negative
                            decimal
                            icon="gbp"
                            iconPosition="left"
                            value={this.state.newTransaction.amount}
                            placeholder="Amount"
                            onChange={(value) => this.onChangeAmount(value)}
                        />
                    </div>
                </div>
                {this.state.errorText !== "" && (
                    <p className={transactionStyles.transactionError}>
                        {this.state.errorText}
                    </p>
                )}
            </Segment>
        );
    }

    transactionEditor() {
        return (
            <>
                {this.state.newTransaction.transactionType === "event"
                    ? this.eventEditor()
                    : this.teeTimeEditor()}
                <Segment vertical>
                    <label className="with-input">Notes</label>
                    <Form>
                        <TextArea
                            value={this.state.newTransaction.note}
                            placeholder="Notes..."
                            onChange={this.onChangeNote.bind(this)}
                        />
                    </Form>
                </Segment>
                <Segment vertical>
                    <Checkbox
                        toggle
                        label="Is Invoiceable"
                        checked={this.state.newTransaction.isInvoiceable}
                        onChange={this.onChangeIsInvoiceable.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <Button onClick={this.cancelNewTransaction.bind(this)}>
                        Cancel
                    </Button>
                    <Button
                        primary
                        className="theme"
                        disabled={this.state.newTransaction.amount === 0}
                        onClick={() =>
                            this.setState({ showConfirmationModal: true })
                        }
                    >
                        Save
                    </Button>
                </Segment>
                {this.renderModal()}
            </>
        );
    }

    eventTransactionsTable() {
        const headers = [
            { key: "date", text: "Date" },
            { key: "amount", text: "Amount" },
            { key: "balance", text: "Balance" },
            { key: "notes", text: "Notes" },
        ];
        const transactions = this.props.transactions.filter(
            (transaction) => transaction.transactionType === "event"
        );

        const data = [
            {
                date: {
                    value: format(
                        new Date(this.props.user.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: this.props.user.createdAt,
                },
                amount: { value: <Label color="blue">£ 0</Label>, search: 0 },
                balance: { value: <Label color="blue">£ 0</Label>, search: 0 },
                notes: { value: "Initial balance", search: "Initial balance" },
            },
        ];

        transactions.forEach((transaction) => {
            data.push({
                date: {
                    value: format(
                        new Date(transaction.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: transaction.createdAt,
                },
                amount: {
                    value: (
                        <Label color={transaction.amount < 0 ? "red" : "green"}>
                            £ {transaction.amount}
                        </Label>
                    ),
                    search: transaction.amount,
                },
                balance: {
                    value: <Label color="blue">£ {transaction.balance}</Label>,
                    search: transaction.balance,
                },
                notes: { value: transaction.note, search: transaction.note },
            });
        });

        return <DataTable headers={headers} data={data} />;
    }

    vouchersTable() {
        const headers = [
            { key: "status", text: "Status" },
            { key: "activatesAt", text: "Activation" },
            { key: "expiresAt", text: "Expiration" },
            { key: "usedAt", text: "Used" },
            { key: "course", text: "Course" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.props.vouchers.map((item) => {
            return {
                status: {
                    value: item.statusLabel,
                },
                activatesAt: {
                    value: format(item.activatesAt, "dd/MM/yyyy"),
                },
                expiresAt: {
                    value: format(item.expiresAt, "dd/MM/yyyy"),
                },
                usedAt: {
                    value: item.usedAt
                        ? format(item.usedAt, "dd/MM/yyyy")
                        : "-",
                },
                course: {
                    value: (
                        <Link to={`/golf-clubs/${item.course.club._id}`}>
                            {item.course.longName}
                        </Link>
                    ),
                },
                actions: {
                    value: (
                        <>
                            <Link to={`/course-vouchers/${item._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                        </>
                    ),
                },
            };
        });

        return <DataTable hideSearch headers={headers} data={data} />;
    }

    teeTimeTransactionsTable() {
        const headers = [
            { key: "date", text: "Date" },
            { key: "amount", text: "Amount" },
            { key: "balance", text: "Balance" },
            { key: "notes", text: "Notes" },
        ];
        const transactions = this.props.transactions.filter(
            (transaction) => transaction.transactionType === "teetime"
        );

        const data = [
            {
                date: {
                    value: format(
                        new Date(this.props.user.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: this.props.user.createdAt,
                },
                amount: { value: <Label color="blue">£ 0</Label>, search: 0 },
                balance: { value: <Label color="blue">£ 0</Label>, search: 0 },
                notes: { value: "Initial balance", search: "Initial balance" },
            },
        ];

        transactions.forEach((transaction) => {
            data.push({
                date: {
                    value: format(
                        new Date(transaction.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: transaction.createdAt,
                },
                amount: {
                    value: (
                        <Label color={transaction.amount < 0 ? "red" : "green"}>
                            £ {transaction.amount}
                        </Label>
                    ),
                    search: transaction.amount,
                },
                balance: {
                    value: <Label color="blue">£ {transaction.balance}</Label>,
                    search: transaction.balance,
                },
                notes: { value: transaction.note, search: transaction.note },
            });
        });

        return <DataTable headers={headers} data={data} />;
    }

    clothingTransactionsTable() {
        const headers = [
            { key: "date", text: "Date" },
            { key: "amount", text: "Amount" },
            { key: "balance", text: "Balance" },
            { key: "notes", text: "Notes" },
        ];
        const transactions = this.props.transactions.filter(
            (transaction) => transaction.transactionType === "clothing"
        );

        const data = [
            {
                date: {
                    value: format(
                        new Date(this.props.user.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: this.props.user.createdAt,
                },
                amount: {
                    value: (
                        <Label color="blue" className="self-center">
                            £ 0
                        </Label>
                    ),
                    search: 0,
                },
                balance: { value: <Label color="blue">£ 0</Label>, search: 0 },
                notes: { value: "Initial balance", search: "Initial balance" },
            },
        ];

        transactions.forEach((transaction) => {
            data.push({
                type: {
                    value: transaction.transactionType.toUpperCase(),
                    search: transaction.transactionType,
                },
                date: {
                    value: format(
                        new Date(transaction.createdAt),
                        "dd/MM/yyyy, HH:mm"
                    ),
                    search: transaction.createdAt,
                },
                amount: {
                    value: (
                        <Label color={transaction.amount < 0 ? "red" : "green"}>
                            £ {transaction.amount}
                        </Label>
                    ),
                    search: transaction.amount,
                },
                balance: {
                    value: <Label color="blue">£ {transaction.balance}</Label>,
                    search: transaction.balance,
                },
                notes: { value: transaction.note, search: transaction.note },
            });
        });

        return <DataTable headers={headers} data={data} />;
    }

    render() {
        return <>{this.editorOrTable()}</>;
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Transactions);
