import axios from "axios";
import Member from "./Member";
const config = require("../config");

class Communication {
    constructor(properties) {
        Object.assign(
            this,
            {
                sendToEntities: [],
                sendToRoles: [],
                sendToUsers: [],
                communicationType: "THIRD_PARTY_MARKETING",
            },
            properties
        );
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "communications", {
            params,
        });
        return {
            communications: data.data.map(
                (comunication) => new Communication(comunication)
            ),
            total: data.total,
        };
    }

    static async getById(id) {
        const { data } = await axios.get(
            config.api_url + "communications/" + id
        );
        return new Communication(data.data);
    }

    async getRecipients(params) {
        const { data } = await axios.get(
            config.api_url + "communications/" + this._id + "/recipients",
            { params }
        );
        return {
            recipients: data.data.map((member) => new Member(member)),
            total: data.total,
        };
    }

    async update() {
        const { data } = await axios.put(
            config.api_url + "communications/" + this._id,
            this
        );
        return new Communication(data.data);
    }

    async store() {
        const { data } = await axios.post(
            config.api_url + "communications",
            this
        );
        return new Communication(data.data);
    }

    delete() {
        return axios.delete(config.api_url + "communications/" + this._id);
    }

    send() {
        return axios.post(
            config.api_url + "communications/" + this._id + "/send"
        );
    }

    sendTest(recipient) {
        return axios.post(
            config.api_url + "communications/" + this._id + "/send-test",
            { recipient }
        );
    }
}

export default Communication;
