import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Segment, Button, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import { isBefore, isAfter, addYears, format } from "date-fns";
import GolfClub from "../../models/GolfClub";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import ValidationError from "../../assets/components/ValidationError";
import DatePicker from "../../assets/components/DatePicker";
import NumberInput from "../../assets/components/NumberInput";
import useDispatchUtilities from "../../utilities/useDispatchUtilities";
import CourseVoucher from "../../models/CourseVoucher";
import PartnerClubContract from "../../models/PartnerClubContract";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import LoadingPlaceholder from "../../assets/components/LoadingPlaceholder";

const Create = ({ history, user, dispatch, match }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [voucher, setVoucher] = useState(null);
    const [courses, setCourses] = useState([]);
    const [confirmedClubId, setConfirmedClubId] = useState(null);

    const { setNotification, setValidationErrors, removeValidationErrors } =
        useDispatchUtilities(dispatch);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const voucher = await CourseVoucher.show(match.params.id);
            voucher.setStateManager(setVoucher);
            setVoucher(voucher);
            const courses = await new GolfClub({
                _id: voucher.course.club._id,
            }).coursesIndex({
                sort: "name",
            });
            setCourses(courses);
            setConfirmedClubId(voucher.course.club._id);
            setLoading(false);
        } catch (e) {
            setNotification("error", "Could not find voucher");
        }
    };

    const save = async () => {
        setSaving(true);
        try {
            await voucher.update();
            setNotification("success", "Changes saved");
            removeValidationErrors();
        } catch (exception) {
            setValidationErrors(exception);
            setNotification("error", "Failed to save changes");
        }
        setSaving(false);
    };

    const deleteVoucher = async () => {
        const confirm = window.confirm(
            "Are you sure you want to delete this voucher?"
        );
        if (confirm === false) return;
        setSaving(true);
        try {
            await voucher.delete();
            setNotification("success", "Voucher deleted");
            history.push("/course-vouchers");
        } catch (exception) {
            setNotification("error", "Failed to delete voucher");
            setSaving(false);
        }
    };

    const fetchSelectedCourseContracts = async (courseId) => {
        const { data } = await PartnerClubContract.index({
            course: courseId,
            usable: true,
        });
        return data;
    };

    const disabledDatesActivation = (day) => {
        if (voucher.contract === null) {
            return isBefore(day, new Date());
        }

        return (
            isBefore(day, voucher.contract.activatesAt) ||
            isAfter(day, voucher.expiresAt)
        );
    };

    const disabledDatesExpiry = (day) => {
        if (voucher.contract === null) {
            return isBefore(day, voucher.activatesAt);
        }

        return (
            isBefore(day, voucher.activatesAt) ||
            isAfter(day, voucher.contract.expiresAt)
        );
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                <div className="container skinny">
                    <div className="row skinny">
                        <div className="col">
                            {loading ? (
                                <LoadingPlaceholder fluid />
                            ) : (
                                <>
                                    <Segment vertical>
                                        <ValidationError field="user" />
                                        <label className="with-input">
                                            Member
                                        </label>
                                        <MemberSearchSelect
                                            disabled
                                            value={voucher.user._id}
                                            onChange={(value) => {
                                                voucher.useStateManager(
                                                    (model) =>
                                                        (model.user = value)
                                                );
                                            }}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <label className="with-input">
                                            Club
                                        </label>
                                        <GolfClubSearchSelect
                                            disabled
                                            memberCanAccess={voucher.user}
                                            value={confirmedClubId}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <ValidationError field="course" />
                                        <label className="with-input">
                                            Course
                                        </label>
                                        <Select
                                            disabled
                                            search
                                            value={voucher.course._id}
                                            options={courses.map(
                                                (club, index) => {
                                                    return {
                                                        key: index,
                                                        text: club.name,
                                                        value: club._id,
                                                    };
                                                }
                                            )}
                                        />
                                    </Segment>
                                    {voucher.contract && (
                                        <Segment vertical>
                                            <p>
                                                The following contract is in use
                                                on this voucher:
                                            </p>
                                            <div className="mt-3">
                                                <Link
                                                    className="mr-2"
                                                    to={`/partner-club-contracts/${voucher.contract._id}`}
                                                >
                                                    {voucher.contract.name}
                                                </Link>
                                                {voucher.contract.statusLabel}
                                                <span className="ml-3">
                                                    Activation:{" "}
                                                    {format(
                                                        voucher.contract
                                                            .activatesAt,
                                                        "dd/MM/yyyy"
                                                    )}
                                                </span>
                                                <span className="ml-3">
                                                    Expiry:{" "}
                                                    {format(
                                                        voucher.contract
                                                            .expiresAt,
                                                        "dd/MM/yyyy"
                                                    )}
                                                </span>
                                            </div>
                                        </Segment>
                                    )}
                                    <Segment vertical>
                                        <ValidationError field="activatesAt" />
                                        <label className="with-input">
                                            Activation date <b>*</b>
                                        </label>
                                        <DatePicker
                                            disabled={voucher.status === "USED"}
                                            value={voucher.activatesAt}
                                            onDayChange={(value) => {
                                                voucher.useStateManager(
                                                    (model) => {
                                                        model.activatesAt =
                                                            value;
                                                    }
                                                );
                                            }}
                                            dayPickerProps={{
                                                disabledDays:
                                                    disabledDatesActivation,
                                            }}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <ValidationError field="expiresAt" />
                                        <label className="with-input">
                                            Expiration date <b>*</b>
                                        </label>
                                        <DatePicker
                                            disabled={voucher.status === "USED"}
                                            value={voucher.expiresAt}
                                            onDayChange={(value) => {
                                                voucher.useStateManager(
                                                    (model) => {
                                                        model.expiresAt = value;
                                                    }
                                                );
                                            }}
                                            dayPickerProps={{
                                                disabledDays:
                                                    disabledDatesExpiry,
                                            }}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <ValidationError field="allocated" />
                                        <label className="with-input">
                                            Players <b>*</b>
                                        </label>
                                        {voucher.contract && (
                                            <p>
                                                <small>
                                                    Vouchers associated with a
                                                    contract must have four
                                                    players
                                                </small>
                                            </p>
                                        )}
                                        <NumberInput
                                            disabled={
                                                voucher.status === "USED" ||
                                                voucher.contract !== null
                                            }
                                            min={1}
                                            max={voucher.contract ? 4 : 8}
                                            value={voucher.players}
                                            onChange={(value) => {
                                                voucher.useStateManager(
                                                    (model) => {
                                                        model.players = value;
                                                    }
                                                );
                                            }}
                                        />
                                    </Segment>
                                    {voucher.status !== "USED" && (
                                        <>
                                            <Button
                                                disabled={saving}
                                                primary
                                                onClick={deleteVoucher}
                                                className="red mt-3"
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                size="large"
                                                floated="right"
                                                className="theme mt-3"
                                                primary
                                                onClick={save}
                                                disabled={saving}
                                                loading={saving}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
