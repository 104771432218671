import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Button, Checkbox, Input } from "semantic-ui-react";
import styles from "./style.module.scss";
import logo from "../../../assets/images/mashie-shield-with-text-white.png";
import useReCaptcha from "../../../utilities/hooks/useRecaptcha";
import Event from "../../../models/Event";
import { format } from "date-fns";
import validatePhone from "pruve/dist/validators/telephone";
import validateEmail from "pruve/dist/validators/email";
import { Separator } from "./components/Separator";
import { ClubAddress } from "./components/ClubAddress";
import { Pill } from "./components/Pill";
import SmallHeader from "./components/SmallHeader";

const Error = ({ field, errors }) => {
  if (errors.length === 0) return null;

  const error = errors.find((error) => Object.keys(error)[0] === field);

  if (!error) return null;
  return <p className={styles.error}>{error[field]}</p>;
};

export default function EventShow({ match }) {
  const [enquiryName, setEnquiryName] = useState("");
  const [enquiryPhone, setEnquiryPhone] = useState("");
  const [enquiryEmail, setEnquiryEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [enquiryTAndCs, setEnquiryTAndCs] = useState(false);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enquiryErrors, setEnquiryErrors] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [enquirySuccess, setEnquirySuccess] = useState(false);

  const { ReCaptcha, captchaRef } = useReCaptcha();

  useEffect(() => {
    setLoading(true);

    let params = new URLSearchParams(document.location.search);
    let referrer = params.get("referrer");
    if (referrer !== null) {
      localStorage.setItem("referrer", referrer);
    }

    const getEvent = async () => {
      const data = await Event.getPublicEvent(match.params.event);
      setEvent(data);
      setLoading(false);
    };

    getEvent();
  }, []);

  const submitEnquiryForm = async () => {
    setServerError(false);

    const enquiryData = {
      name: enquiryName,
      phone: enquiryPhone,
      email: enquiryEmail,
      enquiry: enquiry.length > 0 ? enquiry : undefined,
      tAndCs: enquiryTAndCs,
      referrer: localStorage.getItem("referrer") || undefined,
    };

    let errors = [];
    if (enquiryName === "" || enquiryName.length < 3) {
      errors.push({ name: "Please enter a valid name" });
    }
    if (enquiryPhone === "" || validatePhone(enquiryPhone) === false) {
      errors.push({ phone: "Please enter a valid phone number" });
    }
    if (enquiryEmail === "" || validateEmail(enquiryEmail) === false) {
      errors.push({ email: "Please enter a valid email address" });
    }

    if (enquiryTAndCs === false) {
      errors.push({ privacy: "Please agree to the privacy policy" });
    }

    const captchaToken = captchaRef.getValue();

    if (captchaToken === "") {
      errors.push({ captcha: "Please complete the captcha" });
    }

    if (errors.length > 0) {
      return setEnquiryErrors(errors);
    }

    setEnquiryErrors([]);

    try {
      await Event.sendEnquiry(match.params.event, enquiryData, captchaToken);
      setEnquirySuccess(true);
    } catch (e) {
      setServerError(true);
    }
  };

  const TermsAndConditions = () => {
    if (
      event.termsAndConditions === undefined ||
      event.termsAndConditions === "<p>undefined</p>"
    ) {
      return null;
    }

    return (
      <Fragment>
        <h2 className={styles.h2}>TERMS AND CONDITIONS</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: event.termsAndConditions.replaceAll("<p><br></p>", ""),
          }}
        />
        <Separator />
      </Fragment>
    );
  };

  const EventDescription = () => {
    if (
      event.description === undefined ||
      event.description === "<p>undefined</p>"
    )
      return null;

    return (
      <Fragment key='test'>
        <div className={styles.flexContainer}>
          <div
            dangerouslySetInnerHTML={{
              __html: event.description.replaceAll("<p><br></p>", ""),
            }}
          />
        </div>
        <Separator />
      </Fragment>
    );
  };

  const EventSponsorImage = useCallback(() => {
    if (event === null || event.partnerImages.length === 0) {
      return null;
    }

    const partnerImages = event.partnerImages.filter(
      (partnerImage) => partnerImage.partnerType === "headline"
    );

    if (partnerImages.length === 0) return null;

    return (
      <div className={styles.flexContainer}>
        <h2 className={styles.h2}>EVENT PARTNER</h2>
        <div className={styles.imageContainer}>
          {partnerImages.map((image) => {
            return (
              <img
                key={image.image}
                alt="partner logo"
                className={styles.partnerLogo}
                src={`${process.env.REACT_APP_CONTENT_URL}events/images/${image.image}`}
              />
            );
          })}
        </div>
        <Separator />
      </div>
    );
  }, [event])

  const renderEnquiryForm = () => {
    if (enquirySuccess === true) {
      return (
        <div className={styles.success}>
          <p>
            Your enquiry has been successfully sent. One of our team will be in
            touch with you shortly.
          </p>
        </div>
      );
    }

    return (
      <form className={styles.flexContainer} style={{ gap: "1rem" }} key="form">
        <div>
          <label className={styles.eventLabel}>NAME</label>
          <Input
            className={styles.input}
            fluid
            name="name"
            value={enquiryName || ""}
            onChange={(event) => setEnquiryName(event.target.value)}
            placeholder="Please enter your name"
          />
          <Error field="name" errors={enquiryErrors} />
        </div>
        <div>
          <label className={styles.eventLabel}>PHONE NUMBER</label>
          <Input
            className={styles.input}
            fluid
            name="phone"
            value={enquiryPhone || ""}
            onChange={(event) => setEnquiryPhone(event.target.value)}
            placeholder="Please enter your phone number"
          />
          <Error field="phone" errors={enquiryErrors} />
        </div>
        <div>
          <label className={styles.eventLabel}>EMAIL ADDRESS</label>
          <Input
            className={styles.input}
            fluid
            name="email"
            value={enquiryEmail || ""}
            placeholder="Please enter your email address"
            onChange={(event) => setEnquiryEmail(event.target.value)}
          />
          <Error field="email" errors={enquiryErrors} />
        </div>
        <div>
          <label className={styles.eventLabel}>YOUR ENQUIRY</label>
          <Input
            className={styles.input}
            fluid
            name="enquiry"
            value={enquiry || ""}
            placeholder="Please enter your enquiry here"
            onChange={(event) => setEnquiry(event.target.value)}
            rows={3}
          />
        </div>

        <div>
          <div
            style={{
              margin: "1rem 0",
              gap: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              toggle
              checked={enquiryTAndCs}
              onChange={(_, data) => setEnquiryTAndCs(data.checked)}
            />
            <label style={{ marginTop: "10px" }}  className={styles.eventLabel}>
              Accept Privacy Policy{" "}
              <a
                className={styles.link}
                href="https://www.mashiegolf.co.uk/privacy-policy.html"
              >
                (View Privacy Policy)
              </a>{" "}
            </label>
          </div>
          <Error field="privacy" errors={enquiryErrors} />
        </div>
        <ReCaptcha />
        <Error field="captcha" errors={enquiryErrors} />
        <Button
          type="button"
          className={styles.eventButton}
          onClick={() => submitEnquiryForm()}
        >
          SUBMIT FORM
        </Button>
      </form>
    );
  };

  if (loading || !event) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <h2 className={styles.h2}>Loading...</h2>
      </div>
    );
  }

  const eventImage = event.image
    ? {
        backgroundImage: `url(${process.env.REACT_APP_CONTENT_URL}events/images/${event.image})`,
      }
    : {};

    const ogImage = event.image ? `${process.env.REACT_APP_CONTENT_URL}events/images/${event.image}` : `${process.env.REACT_APP_APP_URL}/og-default.jpg`;

  return (
    <Fragment>
				<meta
					name='description'
					content='Take a look at this MASHIE event. Book your place now.'
				/>
				<meta
					property='og:title'
					content={`MASHIE | ${event.name}`}
					name='title'
				/>
				<meta
					property='og:site_name'
					content={`${process.env.REACT_APP_APP_URL}`}
					name='site_name'
				/>
				<meta
					property='og:url'
					content={`${process.env.REACT_APP_APP_URL}/public/events/${event._id}`}
					name='og:url'
				/>
				<meta
					property='og:description'
					content='Take a look at this MASHIE event. Book your place now.'
					name='og:description'
				/>
				<meta property='og:type' content='website' name='og:type' />
				<meta
					property='og:image'
					content={ogImage}
					name='og:image'
				/>
				<meta property='og:image:width' content='960' name='og:image:width' />
				<meta property='og:image:height' content='540' name='og:image:height' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content={`MASHIE | ${event.name}`} />
				<meta
					name='twitter:description'
					content='Take a look at this MASHIE event. Book your place now.'
				/>
    <div id="events" style={{ paddingBottom: "50px" }}>
      <SmallHeader />
      <div className={styles.fullHeader} style={eventImage} alt="event">
        <Button
          primary
          className={styles.eventButton}
          disabled={false}
          onClick={() => {
            window.location.href = `/public/events`;
          }}
        >
          BACK TO ALL EVENTS
        </Button>
        <img className={styles.entityLogo} src={logo} alt="logo" />
        <h1 className={styles.headerText}>{event.name}</h1>
        <p className={styles.taglineLarge}>
          {format(new Date(event.date), "EEEE do MMMM yyyy")}
        </p>
        <p className={styles.tagline}>
          <ClubAddress event={event} />
        </p>
        <p className={styles.taglineSmall}>{event.bookableDescription}</p>
       {event?.series && (
          <Pill>{event.series.name}</Pill>
       )} 
      </div>
      <div className={styles.contentContainer}>
        <EventSponsorImage key='eventImages'/>
        <EventDescription key='description'/>
        <TermsAndConditions key='terms' />
        <div className={[styles.enquiryForm, styles.flexContainer]}>
          <h2 className={styles.h2}>EVENT ENQUIRY</h2>
          <p className="mb-2 mt-2 p">
            Please contact our Event team by using the form below.
          </p>
          {serverError && (
            <p className={styles.error}>
              Validation failed. Please check the form and try again.
            </p>
          )}
          {renderEnquiryForm()}
        </div>
      </div>
      <SmallHeader bottom />
    </div>
    </Fragment>

  );
}
