import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format, parse } from "date-fns";
import { Input } from "semantic-ui-react";

export default ({ value, onDayChange, dayPickerProps = {}, disabled }) => {
    return (
        <DayPickerInput
            placeholder="Select date"
            component={React.forwardRef((props, ref) => {
                return <Input ref={ref} {...props} />;
            })}
            format="DD/MM/YYYY"
            value={format(value, "dd/MM/yyyy")}
            dayPickerProps={dayPickerProps}
            onDayChange={onDayChange}
            inputProps={{ readOnly: true, disabled: disabled ?? false }}
            formatDate={(date) => format(date, "dd/MM/yyyy")}
            parseDate={(dateString) => parse(dateString, "dd/MM/yyyy", value)}
        />
    );
};
