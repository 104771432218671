import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Segment, Button, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import { isBefore, addYears, format } from "date-fns";
import GolfClub from "../../models/GolfClub";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import ValidationError from "../../assets/components/ValidationError";
import DatePicker from "../../assets/components/DatePicker";
import NumberInput from "../../assets/components/NumberInput";
import useDispatchUtilities from "../../utilities/useDispatchUtilities";
import PartnerClubContract from "../../models/PartnerClubContract";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";

const Create = ({ history, user, dispatch }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [saving, setSaving] = useState(false);
    const [contract, setContract] = useState(
        new PartnerClubContract({
            name: "",
            allocated: 12,
            contractType: "PCP",
            reserved: 0,
            used: 0,
            resalePrice: 0,
            activatesAt: new Date(),
            expiresAt: addYears(new Date(), 1),
            course: null,
        })
    );

    const [courses, setCourses] = useState([]);
    const [confirmedClubId, setConfirmedClubId] = useState(null);
    const [courseContracts, setCourseContracts] = useState([]);

    const { setNotification, setValidationErrors, removeValidationErrors } =
        useDispatchUtilities(dispatch);

    useEffect(() => {
        contract.setStateManager(setContract);
    }, []);

    const store = async () => {
        setSaving(true);
        try {
            await contract.store();
            setNotification("success", "Contract created");
            removeValidationErrors();
            history.push("/partner-club-contracts");
        } catch (exception) {
            setSaving(false);
            setValidationErrors(exception);
            setNotification("error", "Failed to create contract");
        }
    };

    const fetchSelectedCourseContracts = async (courseId) => {
        const { data } = await PartnerClubContract.index({ course: courseId });
        setCourseContracts(data);
    };

    const onChangeConfirmedClub = async (clubId) => {
        const courses = await new GolfClub({ _id: clubId }).coursesIndex({
            sort: "name",
        });
        setConfirmedClubId(clubId);
        setCourses(courses);
        const selectedCourse = courses[0]?._id ?? null;
        if (selectedCourse !== null) {
            fetchSelectedCourseContracts(selectedCourse);
        }
        contract.useStateManager((model) => {
            model.course = selectedCourse;
        });
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                <div className="container skinny">
                    <div className="row skinny">
                        <div className="col">
                            <h2>Create contract</h2>
                            <Segment vertical>
                                <label className="with-input">
                                    Contract type <b>*</b>
                                </label>
                                <Select
                                    value={contract.contractType}
                                    onChange={(_, { value }) => {
                                        contract.useStateManager((model) => {
                                            model.contractType = value;
                                            if (value === "CORPORATE") {
                                                model.allocated = null;
                                                model.reserved = 0;
                                            } else {
                                                model.allocated = 12;
                                            }
                                        });
                                    }}
                                    options={[
                                        {
                                            key: 0,
                                            text: "PCP",
                                            value: "PCP",
                                        },
                                        {
                                            key: 1,
                                            text: "Corporate",
                                            value: "CORPORATE",
                                        },
                                    ]}
                                />
                            </Segment>
                            <Segment vertical>
                                <ValidationError field="name" />
                                <label className="with-input">
                                    Name <b>*</b>
                                </label>
                                <Input
                                    name="name"
                                    value={contract.name}
                                    onChange={({ target }) => {
                                        contract.useStateManager((model) => {
                                            model.name = target.value;
                                        });
                                    }}
                                />
                            </Segment>
                            {contract.allocated !== null && (
                                <>
                                    <Segment vertical>
                                        <ValidationError field="allocated" />
                                        <label className="with-input">
                                            Allocated stock <b>*</b>
                                        </label>
                                        <NumberInput
                                            disabled={
                                                contract.allocated === null
                                            }
                                            value={contract.allocated}
                                            onChange={(value) => {
                                                contract.useStateManager(
                                                    (model) => {
                                                        model.allocated = value;
                                                    }
                                                );
                                            }}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <ValidationError field="reserved" />
                                        <label className="with-input">
                                            Reserved stock <b>*</b>
                                        </label>
                                        <NumberInput
                                            value={contract.reserved}
                                            onChange={(value) => {
                                                contract.useStateManager(
                                                    (model) => {
                                                        model.reserved = value;
                                                    }
                                                );
                                            }}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <ValidationError field="reserved" />
                                        <label className="with-input">
                                            Used stock <b>*</b>
                                        </label>
                                        <NumberInput
                                            value={contract.used}
                                            onChange={(value) => {
                                                contract.useStateManager(
                                                    (model) => {
                                                        model.used = value;
                                                    }
                                                );
                                            }}
                                        />
                                    </Segment>
                                </>
                            )}
                            <Segment vertical>
                                <ValidationError field="resalePrice" />
                                <label className="with-input">
                                    Resale price <b>*</b>
                                </label>
                                <NumberInput
                                    value={contract.resalePrice}
                                    onChange={(value) => {
                                        contract.useStateManager((model) => {
                                            model.resalePrice = value;
                                        });
                                    }}
                                />
                            </Segment>
                            <Segment vertical>
                                <ValidationError field="activatesAt" />
                                <label className="with-input">
                                    Activation date <b>*</b>
                                </label>
                                <DatePicker
                                    value={contract.activatesAt}
                                    onDayChange={(value) => {
                                        contract.useStateManager((model) => {
                                            model.activatesAt = value;
                                        });
                                    }}
                                />
                            </Segment>
                            <Segment vertical>
                                <ValidationError field="expiresAt" />
                                <label className="with-input">
                                    Expiration date <b>*</b>
                                </label>
                                <DatePicker
                                    value={contract.expiresAt}
                                    onDayChange={(value) => {
                                        contract.useStateManager((model) => {
                                            model.expiresAt = value;
                                        });
                                    }}
                                    dayPickerProps={{
                                        disabledDays: (day) => {
                                            return isBefore(
                                                day,
                                                contract.activatesAt
                                            );
                                        },
                                    }}
                                />
                            </Segment>
                            <Segment vertical>
                                <label className="with-input">Club</label>
                                <GolfClubSearchSelect
                                    value={confirmedClubId}
                                    onChange={onChangeConfirmedClub}
                                />
                            </Segment>
                            <Segment vertical>
                                <ValidationError field="course" />
                                <label className="with-input">Course</label>
                                <Select
                                    search
                                    value={contract.course}
                                    onChange={(_, { value }) => {
                                        contract.useStateManager((model) => {
                                            model.course = value;
                                            fetchSelectedCourseContracts(value);
                                        });
                                    }}
                                    options={courses.map((club, index) => {
                                        return {
                                            key: index,
                                            text: club.name,
                                            value: club._id,
                                        };
                                    })}
                                />
                                {courseContracts.length > 0 && (
                                    <>
                                        <p className="mt-3">
                                            This course has the following
                                            contracts:
                                        </p>
                                        {courseContracts.map((item) => (
                                            <div className="mt-3">
                                                <Link
                                                    className="mr-2"
                                                    to={`/partner-club-contracts/${item._id}`}
                                                >
                                                    {item.name}
                                                </Link>
                                                {item.statusLabel}
                                                <span className="ml-3">
                                                    Activation:{" "}
                                                    {format(
                                                        item.activatesAt,
                                                        "dd/MM/yyyy"
                                                    )}
                                                </span>
                                                <span className="ml-3">
                                                    Expiry:{" "}
                                                    {format(
                                                        item.expiresAt,
                                                        "dd/MM/yyyy"
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Segment>
                            <Button
                                size="large"
                                floated="right"
                                className="theme mt-3"
                                primary
                                onClick={store}
                                disabled={saving}
                                loading={saving}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
