import React, { useState, useEffect } from "react";
import {
    Input,
    Segment,
    Select,
    Label,
    Dropdown,
    Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Notes from "../../assets/components/Notes";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import GolfClub from "../../models/GolfClub";
import User from "../../models/User";
import CenterLoader from "../../assets/components/CenterLoader";
import NumberInput from "../../assets/components/NumberInput";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import ValidationError from "../../assets/components/ValidationError";
import formatNumberAsPrice from "../../utilities/formatNumberAsPrice";
import { format, formatDistanceToNow } from "date-fns";
import CourseAvailabilityCalendar from "../../assets/components/golfClub/CourseAvailabilityCalendar";

const Show = ({ user, history, match, dispatch }) => {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [customerNotes, setCustomerNotes] = useState([]);
    const [internalNotes, setInternalNotes] = useState([]);
    const [booking, setBooking] = useState(null);
    const [confirmedClub, setConfirmedClub] = useState(null);
    const [courses, setCourses] = useState([]);
    const [nearby, setNearby] = useState([]);
    const [availableContracts, setAvailableContracts] = useState([]);
    const statuses = [
        {
            key: 0,
            text: "Requested by member",
            value: "requested",
        },
        {
            key: 2,
            text: "Awaiting member confirmation",
            value: "awaiting confirmation",
        },
        {
            key: 3,
            text: "Confirmed by member",
            value: "confirmed",
        },
        {
            key: 4,
            text: "Booked by MASHIE",
            value: "booked",
        },
        {
            key: 5,
            text: "Cancellation requested by member",
            value: "cancellation requested",
        },
        {
            key: 6,
            text: "Cancelled by MASHIE",
            value: "cancelled",
        },
    ];

    const paymentStatuses = [
        {
            key: 0,
            text: "Unpaid",
            value: "unpaid",
        },
        {
            key: 1,
            text: "Pay MASHIE Now",
            value: "pay mashie now",
        },
        {
            key: 2,
            text: "Pay club Now",
            value: "pay club now",
        },
        {
            key: 3,
            text: "Pay club on arrival",
            value: "pay club on arrival",
        },
        {
            key: 4,
            text: "Paid",
            value: "paid",
        },
        {
            key: 5,
            text: "No payment required",
            value: "no payment required",
        },
        {
            key: 6,
            text: "Refunded",
            value: "refunded",
        },
    ];

    const statusDescriptions = {
        requested:
            "This booking has been requested by the member and not yet actioned by MASHIE.",
        "awaiting confirmation":
            "This booking has been actioned in part by MASHIE, and is now awaiting confirmation from the member.",
        confirmed:
            "The member has confirmed they are happy with the booking offered by MASHIE.",
        booked: "MASHIE has made the booking with the club. No further action is required.",
        "cancellation requested":
            "The member has requested to cancel their booking.",
        cancelled:
            "This booking has been cancelled by MASHIE. No further action is required.",
    };

    useEffect(() => {
        getBooking();
        getCustomerNotes();
        getInternalNotes();
    }, []);

    const notification = (type, text) => {
        dispatch(NotificationActions.create({ type, text }));
    };

    const handleValidationErrors = (error) => {
        if (error.response.status === 422) {
            dispatch(ValidationActions.create(error.response.data.errors));
        }
    };

    const getBooking = async () => {
        const { id } = match.params;
        try {
            const booking = await TeeTimeBooking.getById(id);
            booking.user = new User(booking.user);
            let nearby = [];
            if (booking.isCreditBooking === false) {
                nearby = await new GolfClub(
                    booking.choices[0].course.club
                ).nearby();
            }

            let courses = [];
            if (booking.hasConfirmedBooking === true) {
                const club = await GolfClub.show(
                    booking.confirmedBooking.course.club._id
                );
                courses = await club.coursesIndex({
                    sort: "name",
                });

                setCourses(courses);
                setConfirmedClub(club);
            }

            setBooking(booking);
            setNearby(nearby);
            const contracts = await booking.getAvailableContracts();
            setAvailableContracts(contracts);
        } catch (error) {
            notification("error", "Failed to entity, please try again");
        }
        setLoading(false);
    };

    const getCustomerNotes = async () => {
        const { id } = match.params;
        const notes = await TeeTimeBooking.getCustomerNotes(id);
        setCustomerNotes(notes);
    };

    const getInternalNotes = async () => {
        const { id } = match.params;
        const notes = await TeeTimeBooking.getInternalNotes(id);
        setInternalNotes(notes);
    };

    const addConfirmedBooking = async () => {
        booking.confirmedBooking = {
            date: booking.date,
            time: booking.timeslot.split(" ")[0],
            players: booking.players,
            price: booking.price ?? 0,
            bookingFee: booking.bookingFee ?? 0,
            course: booking.choices[0].course,
        };

        const club = await GolfClub.show(booking.choices[0].course.club._id);
        const courses = await club.coursesIndex({ sort: "name" });
        setConfirmedClub(club);
        setCourses(courses);
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeStatus = (value) => {
        booking.status = value;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangePaymentStatus = (value) => {
        booking.paymentStatus = value;
        setBooking(new TeeTimeBooking(booking));
    };

    const save = async () => {
        setSaving(true);
        try {
            await booking.update();
            dispatch(ValidationActions.remove());
            notification("success", "Tee time request saved");
            const contracts = await booking.getAvailableContracts();
            setAvailableContracts(contracts);
        } catch (error) {
            notification("error", "Failed to save Tee time request");
            handleValidationErrors(error);
        }
        setSaving(false);
    };

    const clubContacts = (club) => {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    };

    const onChangeConfirmedDate = (selectedDay) => {
        selectedDay.setHours(12);
        booking.confirmedBooking.date = selectedDay;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedTime = (value) => {
        booking.confirmedBooking.time = value;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedPlayers = (value) => {
        booking.confirmedBooking.players = value;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangePaymentLink = (value) => {
        booking.paymentLink = value === "" ? null : value;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedPrice = (value) => {
        booking.confirmedBooking.price = value;
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedBookingFee = (value) => {
        booking.confirmedBooking.bookingFee = value || "";
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedClub = async (value) => {
        const club = await GolfClub.show(value);
        const courses = await club.coursesIndex({ sort: "name" });
        booking.confirmedBooking.course = courses[0] ?? null;
        setConfirmedClub(club);
        setCourses(courses);
        setBooking(new TeeTimeBooking(booking));
    };

    const onChangeConfirmedCourse = (value) => {
        booking.confirmedBooking.course = courses.find(
            ({ _id }) => _id === value
        );
        setBooking(new TeeTimeBooking(booking));
    };

    const updateContract = async () => {
        const confirmed = window.confirm(
            "Are you sure you want to consume a credit against this booking?"
        );
        if (confirmed === false) return;

        try {
            await booking.updateContract(availableContracts[0]._id);
            booking.contract = availableContracts[0];
            notification("success", "Contract updated");
            setBooking(new TeeTimeBooking(booking));
            setAvailableContracts([]);
        } catch (_) {
            notification("error", "Failed to update contract");
        }
    };

    const deleteContract = async () => {
        const confirmed = window.confirm(
            "Are you sure you want to return the contract credit to the allocated pool?"
        );
        if (confirmed === false) return;

        try {
            await booking.deleteContract();
            booking.contract = null;
            notification("success", "Contract Removed from booking");
            setBooking(new TeeTimeBooking(booking));
        } catch (_) {
            notification("error", "Failed to remove contract from booking");
        }
    };

    const showRefundContract = () => {
        if (
            booking.contract !== null &&
            booking.contract.allocated !== null &&
            booking.status === "cancelled"
        ) {
            return (
                <div className={styles.choice}>
                    <>
                        <p>
                            This booking was made using a contract (PCP /
                            corporate). It has since been cancelled. Would you
                            like to return the contract credit to the allocated
                            pool?
                        </p>
                        <Button
                            onClick={deleteContract}
                            primary
                            className="theme"
                        >
                            Return to pool
                        </Button>
                    </>
                </div>
            );
        }
    };

    const showConsumeContract = () => {
        if (availableContracts.length === 0) {
            return null;
        }

        return (
            <div className={styles.choice}>
                <>
                    <p>
                        This course has contract (PCP / corporate) availability
                        on the requested date. Would you like to consume a
                        credit against this booking?
                    </p>
                    <p>
                        <b>Contract: </b>
                        {availableContracts[0].name}
                        <br />
                        <b>Used stock: </b>
                        {availableContracts[0].used}
                        <br />
                        <b>Available stock: </b>
                        {availableContracts[0].available === null
                            ? "∞"
                            : availableContracts[0].available}
                    </p>
                    <Button onClick={updateContract} primary className="theme">
                        Consume
                    </Button>
                </>
            </div>
        );
    };

    const statusTab = () => {
        return (
            <>
                <Segment vertical>
                    <div className="row skinny">
                        <div className="col">
                            <ValidationError field="status" />
                            <h3>Booking status</h3>
                            <label className="with-input">Status</label>
                            <Select
                                fluid
                                value={booking.status}
                                onChange={(_, { value }) =>
                                    onChangeStatus(value)
                                }
                                options={statuses}
                            />
                            <p className="mt-3">
                                {statusDescriptions[booking.status]}
                            </p>
                            <p className="mt-3">
                                <b>
                                    Note: The customer will be notified of any
                                    status changes.
                                </b>
                            </p>
                        </div>
                    </div>
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={saving}
                        onClick={save}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    };

    const onAddInternalNote = async (value) => {
        const newNote = await booking.storeInternalNote(value);
        setInternalNotes([newNote, ...internalNotes]);
    };

    const onAddCustomerNote = async (value) => {
        const newNote = await booking.storeCustomerNote(value);
        setCustomerNotes([newNote, ...customerNotes]);
    };

    const paymentTab = () => {
        return (
            <>
                <Segment vertical>
                    <div className="row">
                        <div className="col">
                            <label className="with-input">Payment link</label>
                            <Input
                                fluid
                                placeholder="Payment link"
                                value={booking.paymentLink || ""}
                                onChange={({ target }) =>
                                    onChangePaymentLink(target.value)
                                }
                            />
                        </div>
                        <div className="col">
                            <ValidationError field="paymentStatus" />
                            <label className="with-input">Payment status</label>
                            <Select
                                fluid
                                value={booking.paymentStatus}
                                onChange={(_, { value }) =>
                                    onChangePaymentStatus(value)
                                }
                                options={paymentStatuses}
                            />
                            {booking.refundedAt instanceof Date ? (
                                <p className="mt-3">
                                    This booking was refunded{" "}
                                    {format(
                                        booking.refundedAt,
                                        "dd LLLL yyyy, HH:mm"
                                    )}
                                </p>
                            ) : (
                                booking.paymentStatus === "refunded" && (
                                    <p className="mt-3">
                                        <b>
                                            Changing the payment status to
                                            "Refunded" will issue a credit
                                            refund to the member. This will only
                                            occur for credit bookings for which
                                            a refund has not already been
                                            issued.
                                        </b>
                                    </p>
                                )
                            )}
                        </div>
                    </div>
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={saving}
                        onClick={save}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    };

    const numberOfPlayers = () => {
        if (booking.players > 8) {
            return booking.players + "+";
        }

        return booking.players;
    };

    const listChoices = () => {
        return (
            <div className={styles.choice}>
                <h3>Requested club</h3>
                {booking.choices.map((choice, index) => {
                    return (
                        <div key={index}>
                            <p className="m-0">
                                <Link
                                    key={index}
                                    to={`/golf-clubs/${choice.course.club._id}`}
                                >
                                    {choice.course.club.name}
                                </Link>
                            </p>
                            <p className="m-0">{choice.course.name}</p>
                            <p className="m-0">
                                {choice.course.club.email ?? (
                                    <i>Club email not available</i>
                                )}
                            </p>
                            {clubContacts(choice.course.club)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const listNearby = () => {
        return (
            <div className={styles.choice}>
                <h3>Alternative clubs (within 60 miles)</h3>
                {nearby.length === 0 ? (
                    <p>No alternative clubs were found</p>
                ) : (
                    nearby.map((club, index) => {
                        return (
                            <div key={index} className="mt-3">
                                <p className="m-0">
                                    <Link to={`/golf-clubs/${club._id}`}>
                                        {club.name}
                                    </Link>
                                </p>
                                <p className="m-0">
                                    {club.distance} miles away
                                </p>
                            </div>
                        );
                    })
                )}
            </div>
        );
    };

    const updateConfirmedBooking = async () => {
        try {
            await booking.updateConfirmedBooking();
            notification("success", "Booking updated");
        } catch (error) {
            handleValidationErrors(error);
            notification("error", "Something went wrong");
        }
    };

    const showRestrictionNotice = () => {
        if (booking.voucher !== null) {
            return (
                <p className="mt-3">
                    <b>
                        This is a voucher booking, therefore some fields have
                        been restricted
                    </b>
                </p>
            );
        }

        if (booking.isCreditBooking === true) {
            return (
                <p className="mt-3">
                    <b>
                        This is a credit booking, therefore some fields have
                        been restricted
                    </b>
                </p>
            );
        }

        if (booking.contract !== null) {
            return (
                <p className="mt-3">
                    <b>
                        This booking is associated with a contract, therefore
                        some fields have been restricted
                    </b>
                </p>
            );
        }

        return null;
    };

    const confirmBooking = () => {
        if (booking.hasConfirmedBooking === false) {
            return (
                <Button
                    primary
                    className="theme mt-3"
                    onClick={addConfirmedBooking}
                >
                    Add booking
                </Button>
            );
        }

        return (
            <Segment vertical>
                <div className="row skinny">
                    <div className="col">
                        {showRestrictionNotice()}
                        <ValidationError field="confirmedBooking" />
                        <Segment vertical>
                            <label className="with-input">Club</label>
                            <GolfClubSearchSelect
                                disabled={
                                    booking.isCreditBooking ||
                                    booking.voucher !== null ||
                                    booking.contract !== null
                                }
                                value={confirmedClub?._id}
                                onChange={onChangeConfirmedClub}
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">Course</label>
                            <Select
                                disabled={
                                    booking.isCreditBooking ||
                                    booking.voucher !== null ||
                                    booking.contract !== null
                                }
                                search
                                value={
                                    booking.confirmedBooking.course?._id ?? null
                                }
                                onChange={(_, { value }) =>
                                    onChangeConfirmedCourse(value)
                                }
                                options={courses.map((club, index) => {
                                    return {
                                        key: index,
                                        text: club.name,
                                        value: club._id,
                                    };
                                })}
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">
                                No. of players{" "}
                                {booking.voucher !== null
                                    ? `(max ${booking.voucher.players})`
                                    : booking.isCreditBooking ||
                                      booking.contract !== null
                                    ? "(max 4)"
                                    : ""}
                            </label>
                            <NumberInput
                                placeholder="No. of players"
                                value={booking.confirmedBooking.players}
                                onChange={onChangeConfirmedPlayers}
                                min={1}
                                max={
                                    booking.voucher !== null
                                        ? booking.voucher.players
                                        : booking.isCreditBooking ||
                                          booking.contract !== null
                                        ? 4
                                        : 100
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            <ValidationError field="price" />
                            <label className="with-input">Price (total)</label>
                            <NumberInput
                                icon={confirmedClub?.currency.icon ?? ""}
                                iconPosition="left"
                                placeholder="Price"
                                fluid
                                decimal
                                value={booking.confirmedBooking.price}
                                onChange={onChangeConfirmedPrice}
                                disabled={
                                    booking.isCreditBooking ||
                                    booking.voucher !== null
                                }
                            />
                        </Segment>
                        {(booking.isCreditBooking === false ||
                            booking.voucher === null) && (
                            <Segment vertical>
                                <ValidationError field="bookingFee" />
                                <label className="with-input">
                                    Booking fee (total)
                                </label>
                                <NumberInput
                                    icon={"gbp"}
                                    decimal
                                    iconPosition="left"
                                    placeholder="Booking fee"
                                    fluid
                                    value={booking.confirmedBooking.bookingFee}
                                    onChange={onChangeConfirmedBookingFee}
                                />
                            </Segment>
                        )}
                        <Segment vertical>
                            <label className="with-input">Date & time</label>
                            <CourseAvailabilityCalendar
                                dateValue={booking.confirmedBooking.date}
                                timeValue={booking.confirmedBooking.time}
                                member={booking.user._id}
                                voucher={booking.voucher?._id}
                                course={booking.confirmedBooking.course._id}
                                onChangeDate={onChangeConfirmedDate}
                                onChangeTime={onChangeConfirmedTime}
                                isCreditBooking={
                                    booking.isCreditBooking ||
                                    booking.contract !== null
                                }
                                bookingId={booking._id}
                                showTimeInput
                            />
                        </Segment>
                        <Segment vertical>
                            <Button
                                primary
                                className="theme"
                                onClick={updateConfirmedBooking}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </Segment>
        );
    };

    const deleteBooking = async () => {
        const confirm = window.confirm(
            "Are you sure you want to delete this tee time request?"
        );
        if (confirm === true) {
            try {
                await booking.delete();
                history.push("/tee-time-requests");
                notification("success", "Tee time deleted");
            } catch (_) {
                notification("error", "Something went wrong");
            }
        }
    };

    const watchBooking = async () => {
        const confirm = window.confirm(
            "Are your sure you want to watch this teetime?"
        );
        if (confirm === true) {
            try {
                await booking.watch();
                booking.watchers.push(user);
                setBooking(booking);
                notification("success", "You are now watching this tee time");
            } catch (_) {
                notification("error", "Failed to watch tee time");
            }
        }
    };

    const showWatchButton = () => {
        if (user.isWatchingTeeTime(booking)) {
            return (
                <Label as="a" className="mr-2">
                    Watching
                </Label>
            );
        }

        return (
            <Button primary className="theme" onClick={watchBooking}>
                Watch
            </Button>
        );
    };

    const claimTeeTime = async () => {
        const confirm = window.confirm(
            "Are yuo sure you want to claim this teetime?"
        );
        if (confirm === true) {
            try {
                await booking.claim();
                booking.claimedBy = user;
                setBooking(booking);
                notification("success", "Tee time claimed");
            } catch (_) {
                notification("error", "Failed to claim tee time");
            }
        }
    };

    const showClaimedBy = () => {
        if (typeof booking.claimedBy !== "undefined") {
            return (
                <Label as="a" image className="mr-2">
                    <img src={User.profileImageUrlForUser(booking.claimedBy)} />
                    Claimed by {booking.claimedBy.name}
                </Label>
            );
        }

        return null;
    };

    const showClaimButton = () => {
        if (user.hasClaimedTeeTime(booking) === false) {
            return (
                <Button primary className="theme" onClick={claimTeeTime}>
                    Claim
                </Button>
            );
        }
    };

    const onChangeTab = (key) => {
        if (key === "customerNotes") {
            getCustomerNotes();
            booking.markCustomerNotesRead();
            booking.unreadMessages = 0;
            setBooking(booking);
        }
        if (key === "internalNotes") {
            getInternalNotes();
        }
    };

    const customerNotesTabText = () => {
        const showUnreadCount =
            user.isWatchingTeeTime(booking) || user.hasClaimedTeeTime(booking);
        if (showUnreadCount && booking.unreadMessages > 0) {
            return (
                <div>
                    Customer messages{" "}
                    <Label circular color="red" className="ml-1" size="small">
                        {booking.unreadMessages}
                    </Label>
                </div>
            );
        }

        return "Customer messages";
    };

    const showQuotedPrice = () => {
        const { price = null } = booking;
        if (price === null) {
            return <i>No price available</i>;
        }

        let priceString = `${
            confirmedClub?.currency?.symbol ?? "£"
        }${formatNumberAsPrice(price)}`;
        if (booking.bookingFee > 0) {
            priceString += ` (+ £${formatNumberAsPrice(
                booking.bookingFee
            )} booking fee)`;
        }

        return priceString;
    };

    const showContractPaymentStatusWarning = () => {
        const statuses = [
            "pay mashie now",
            "paid",
            "refunded",
            "no payment required",
        ];
        if (
            booking.contract !== null &&
            booking.isCreditBooking === false &&
            statuses.includes(booking.paymentStatus) === false
        ) {
            return (
                <p className="mb-5">
                    <b>Warning: </b>This booking is associated with a contract,
                    but has a payment status of {booking.paymentStatus}.
                </p>
            );
        }
    };

    const body = () => {
        if (loading === true) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <div className="col-12 mb-3">
                        <h2>Tee Time Request</h2>
                    </div>
                    <div className="col-12 mb-3">
                        {showClaimedBy()}
                        {showClaimButton()}
                        {showWatchButton()}
                    </div>
                    <div className="col-12">
                        <Dropdown
                            text="Options"
                            icon="cog"
                            floating
                            labeled
                            button
                            className="icon"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Header icon="cog" content="Options" />
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    icon="trash"
                                    text="Delete"
                                    onClick={deleteBooking}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="row skinny mb-5">
                    <div className="col-4">
                        <div className={styles.choice}>
                            <h3>Original booking details</h3>
                            <p>
                                <span>
                                    <b>Requested by:</b>
                                </span>
                                <span className="ml-2">
                                    <Link to={`/members/${booking.user._id}`}>
                                        {booking.user.name}
                                    </Link>
                                </span>
                                <span>
                                    {" "}
                                    on{" "}
                                    {format(
                                        booking.createdAt,
                                        "dd LLLL yyyy, HH:mm"
                                    ) +
                                        " (" +
                                        formatDistanceToNow(booking.createdAt) +
                                        ")"}
                                </span>
                            </p>
                            <p>
                                <b>Requested date & time:</b>{" "}
                                {format(booking.date, "dd LLLL yyyy")},{" "}
                                {booking.timeslot}
                            </p>
                            <p>
                                <b>No. of players:</b> {numberOfPlayers()}
                            </p>
                            <p>
                                <b>Additional requests:</b>{" "}
                                {booking.additionalRequests.length > 0 ? (
                                    booking.additionalRequests.join(", ")
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                            <p>
                                <b>
                                    {booking.isCreditBooking
                                        ? "Credit"
                                        : "Quoted"}{" "}
                                    price:{" "}
                                </b>
                                {showQuotedPrice()}
                            </p>
                            <p>
                                <b>Notes:</b>{" "}
                                {booking.notes && booking.notes.length > 0 ? (
                                    booking.notes
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                            <p>
                                <b>Booking type:</b>{" "}
                                {booking.isCreditBooking ? "Credit" : "Cash"}
                            </p>
                            <p>
                                <b>Contract:</b>{" "}
                                {booking.contract ? (
                                    <Link
                                        to={`/partner-club-contracts/${booking.contract._id}`}
                                    >
                                        {booking.contract.name}
                                    </Link>
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                            <p>
                                <b>Voucher:</b>{" "}
                                {booking.voucher ? (
                                    <Link
                                        to={`/course-vouchers/${booking.voucher._id}`}
                                    >
                                        View voucher
                                    </Link>
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                        </div>
                        {showConsumeContract()}
                        {showRefundContract()}
                    </div>
                    <div className="col-4">{listChoices()}</div>
                    {booking.isCreditBooking === false ||
                        booking.voucher === null ||
                        (booking.contract === null && (
                            <div className="col-4">{listNearby()}</div>
                        ))}
                </div>
                {showContractPaymentStatusWarning()}
                <Tabs
                    defaultActiveKey="confirmedBooking"
                    id="tabs"
                    onSelect={onChangeTab}
                >
                    <Tab eventKey="confirmedBooking" title="Booking">
                        <div className="row skinny mb-3">
                            <div className="col">{confirmBooking()}</div>
                        </div>
                    </Tab>
                    <Tab eventKey="status" title="Status">
                        {statusTab()}
                    </Tab>
                    <Tab
                        eventKey="customerNotes"
                        title={customerNotesTabText()}
                    >
                        <Notes
                            placeholder="Type to send a message to member"
                            notes={customerNotes}
                            onSave={onAddCustomerNote}
                        />
                    </Tab>
                    <Tab eventKey="internalNotes" title="Internal notes">
                        <Notes
                            placeholder="Type to add a new note"
                            notes={internalNotes}
                            onSave={onAddInternalNote}
                        />
                    </Tab>
                    <Tab eventKey="payment" title="Payment">
                        {paymentTab()}
                    </Tab>
                </Tabs>
            </div>
        );
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                {body()}
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
