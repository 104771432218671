import React, { useState } from "react";
import { Segment, Select, Button, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import format from "date-fns/format";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import NumberInput from "../../assets/components/NumberInput";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import CourseVoucher from "../../models/CourseVoucher";
import Member from "../../models/Member";
import GolfClub from "../../models/GolfClub";
import GolfCourse from "../../models/GolfCourse";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import CourseAvailabilityCalendar from "../../assets/components/golfClub/CourseAvailabilityCalendar";

const Create = ({ history }) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [member, setMember] = useState(null);
    const [club, setClub] = useState(null);
    const [course, setCourse] = useState(null);
    const [courseCreditPrice, setCourseCreditPrice] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timeslot, setTimeslot] = useState("10:00 - 11:00");
    const [isCreditBooking, setIsCreditBooking] = useState(false);
    const [players, setPlayers] = useState(4);
    const [creditPrice, setCreditPrice] = useState(1);
    const [clubCourses, setClubCourses] = useState([]);
    const [bookingType, setBookingType] = useState("CASH");
    const [userVouchers, setUserVouchers] = useState([]);
    const [voucher, setVoucher] = useState(null);
    const [memberAccessClubsFilter, setMemberAccessClubsFilter] =
        useState(true);

    const save = async () => {
        setSaving(true);
        try {
            const booking = new TeeTimeBooking({
                user: member._id,
                choices: [{ course: course._id }],
                players,
                date: selectedDate,
                isCreditBooking,
                timeslot,
            });
            if (isCreditBooking) {
                booking.price = creditPrice;
            }
            if (voucher !== null) {
                booking.voucher = voucher._id;
            }
            const response = await booking.store();
            setSaving(false);
            history.push("/tee-time-requests/" + response._id);
        } catch (e) {
            setSaving(false);
            setError(true);
        }
    };

    const onChangeMember = async (value) => {
        const member = await Member.show(value);
        setMember(member);
        setClub(null);
        setCourse(null);
        if (member.canAccessTeeTimeCredit === false) {
            setIsCreditBooking(false);
        }
        if (bookingType === "VOUCHER") {
            getUserVouchers(member._id);
        }
    };

    const getUserVouchers = async (userId) => {
        const { data } = await CourseVoucher.userIndex(userId, {
            usable: true,
        });
        setUserVouchers(data);
    };

    const onChangeDate = (date) => {
        date.setHours(12);
        setSelectedDate(date);
    };

    const onChangeTime = (time) => {
        setTimeslot(time);
    };

    const getClubCourses = async (
        clubId,
        supportsCreditBookings = isCreditBooking
    ) => {
        const params = { sort: "name" };
        if (supportsCreditBookings === true) {
            params.supportsCreditBookings = true;
        }
        const clubCourses = await new GolfClub({
            _id: clubId,
        }).coursesIndex(params);

        setClubCourses(clubCourses);

        if (clubCourses.length > 0) {
            setCourse(clubCourses[0]);
            getCourseCreditPrice(clubCourses[0]._id);
            if (clubCourses[0].supportsCreditBookings === false) {
                setIsCreditBooking(false);
            }
        } else {
            setCourse(null);
        }
    };

    const onChangeClub = async (value) => {
        getClubCourses(value);
        setClub(value);
    };

    const onChangeCourse = (value) => {
        const selected = clubCourses.find(({ _id }) => _id === value);
        setCourse(selected);
        getCourseCreditPrice(selected._id);

        if (selected.supportsCreditBookings === false) {
            setIsCreditBooking(false);
        }
    };

    const onChangeIsCreditBooking = (on) => {
        setIsCreditBooking(on);
        setPlayers(on ? 4 : 1);
        if (club !== null) {
            getClubCourses(club, on);
        }
    };

    const getCourseCreditPrice = async (courseId) => {
        const { price } = await GolfCourse.creditPrice(courseId, member._id);
        setCourseCreditPrice(price);
        setCreditPrice(price);
    };

    const onSelectVoucher = (value) => {
        const selected = userVouchers.find(({ _id }) => _id === value);
        onChangeIsCreditBooking(selected.contract !== null);
        setClub(selected.course.club._id);
        setCourse(selected.course);
        setVoucher(selected);
    };

    const maxPlayersNotice = () => {
        if (voucher !== null) {
            return (
                <span className="ml-3">
                    The voucher associated with this booking has a maximum of{" "}
                    {voucher.players} players
                </span>
            );
        }

        if (isCreditBooking === true) {
            return (
                <span className="ml-3">
                    Credit bookings can have a maximum of four players
                </span>
            );
        }
    };

    const body = () => {
        return (
            <div className="container">
                <h2>Create Tee Time Request</h2>
                <label className="with-input">Booking type</label>
                <Segment vertical>
                    <Select
                        value={bookingType}
                        onChange={(_, { value }) => {
                            if (value === "CASH") {
                                onChangeIsCreditBooking(false);
                            }
                            if (value === "CREDIT") {
                                onChangeIsCreditBooking(true);
                            }

                            if (value === "VOUCHER") {
                                onChangeIsCreditBooking(false);
                                setCourse(null);
                                setClub(null);
                                setMember(null);
                            }

                            setBookingType(value);
                        }}
                        options={[
                            { key: 0, value: "CASH", text: "Cash" },
                            { key: 1, value: "CREDIT", text: "Credit" },
                            { key: 2, value: "VOUCHER", text: "Voucher" },
                        ]}
                    />
                    {bookingType === "CREDIT" &&
                        member?.canAccessTeeTimeCredit === false && (
                            <span className="ml-3">
                                <b>
                                    The selected member does not have access to
                                    tee time credit
                                </b>
                            </span>
                        )}
                </Segment>
                <Segment vertical>
                    <label className="with-input">Member</label>
                    <MemberSearchSelect
                        onChange={(value) => onChangeMember(value)}
                        value={member?._id ?? null}
                        hasUsableVoucher={bookingType === "VOUCHER"}
                        active
                    />
                    {bookingType === "CREDIT" &&
                        member?.canAccessTeeTimeCredit && (
                            <span className="ml-3">
                                {`Tee time credit balance: £${member.teeTimeCreditBalance}`}
                            </span>
                        )}
                </Segment>
                {member && (
                    <>
                        {bookingType === "VOUCHER" ? (
                            <Segment vertical>
                                <label className="with-input">Voucher</label>
                                <Select
                                    value={voucher?._id}
                                    options={userVouchers.map((item, index) => {
                                        return {
                                            key: index,
                                            text: `${
                                                item.course.longName
                                            } (${format(
                                                item.activatesAt,
                                                "dd/MM/yyyy"
                                            )} - ${format(
                                                item.expiresAt,
                                                "dd/MM/yyyy"
                                            )})`,
                                            value: item._id,
                                        };
                                    })}
                                    onChange={(_, { value }) =>
                                        onSelectVoucher(value)
                                    }
                                />
                            </Segment>
                        ) : (
                            <>
                                <Segment vertical>
                                    <label className="with-input">Club</label>
                                    <GolfClubSearchSelect
                                        value={club ?? null}
                                        onChange={(value) =>
                                            onChangeClub(value)
                                        }
                                        memberCanAccess={
                                            memberAccessClubsFilter
                                                ? member._id
                                                : null
                                        }
                                        acceptsCredit={isCreditBooking}
                                    />
                                    <Checkbox
                                        toggle
                                        label="Hide clubs the member cannot access"
                                        checked={memberAccessClubsFilter}
                                        className="ml-3"
                                        onChange={() => {
                                            setMemberAccessClubsFilter(
                                                memberAccessClubsFilter ===
                                                    false
                                            );
                                            setClub(null);
                                            setCourse(null);
                                        }}
                                    />
                                </Segment>
                                {club && (
                                    <Segment vertical>
                                        <label className="with-input">
                                            Course
                                        </label>
                                        <Select
                                            search
                                            value={course?._id ?? null}
                                            onChange={(_, { value }) =>
                                                onChangeCourse(value)
                                            }
                                            options={clubCourses.map(
                                                (course, index) => {
                                                    const text = `${
                                                        course.name
                                                    } ${
                                                        course.supportsCreditBookings
                                                            ? "(accepts credit)"
                                                            : ""
                                                    }`;
                                                    return {
                                                        key: index,
                                                        text,
                                                        value: course._id,
                                                    };
                                                }
                                            )}
                                        />
                                        {isCreditBooking && (
                                            <span className="ml-3">
                                                {courseCreditPrice
                                                    ? `Credit price for selected member: £
                                ${courseCreditPrice}`
                                                    : "No credit price available for selected member"}
                                            </span>
                                        )}
                                    </Segment>
                                )}
                            </>
                        )}
                        {course && (
                            <>
                                {bookingType === "CREDIT" &&
                                    course.supportsCreditBookings &&
                                    member.canAccessTeeTimeCredit &&
                                    member.hasSufficientTeeTimeCredit(
                                        courseCreditPrice
                                    ) === false && (
                                        <Segment vertical>
                                            <p>
                                                <b>
                                                    The selected member does not
                                                    have enough credit to make
                                                    this booking
                                                </b>
                                            </p>
                                        </Segment>
                                    )}
                                <Segment vertical>
                                    <label className="with-input">
                                        No. of players
                                    </label>
                                    <NumberInput
                                        min={1}
                                        max={
                                            voucher
                                                ? voucher.players
                                                : isCreditBooking
                                                ? 4
                                                : 100
                                        }
                                        placeholder="No. of players"
                                        value={players}
                                        onChange={(value) => setPlayers(value)}
                                    />
                                    {maxPlayersNotice()}
                                </Segment>
                                <Segment vertical>
                                    <label className="with-input">
                                        Date & time
                                    </label>
                                    <CourseAvailabilityCalendar
                                        dateValue={selectedDate}
                                        timeValue={timeslot}
                                        member={member._id}
                                        course={course._id}
                                        voucher={voucher?._id}
                                        onChangeDate={onChangeDate}
                                        onChangeTime={onChangeTime}
                                        isCreditBooking={isCreditBooking}
                                        showTimeslotSelection
                                        defaultFirstAvailable
                                    />
                                </Segment>
                                {bookingType === "CREDIT" && (
                                    <Segment vertical>
                                        <label className="with-input">
                                            Credit price
                                        </label>
                                        <p>
                                            This amount will be deducted from
                                            the member's tee time credit on
                                            creation of the booking. You can
                                            amend the final price later if
                                            needed.
                                        </p>
                                        <NumberInput
                                            min={1}
                                            placeholder="Credit price"
                                            value={creditPrice}
                                            onChange={(value) =>
                                                setCreditPrice(value)
                                            }
                                        />
                                    </Segment>
                                )}
                                {bookingType === "CREDIT" &&
                                creditPrice > member.teeTimeCreditBalance ? (
                                    <p className="mt-3">
                                        <b>
                                            The member does not have enough
                                            credit to make this booking.
                                        </b>
                                    </p>
                                ) : (
                                    <Segment vertical className="text-right">
                                        <Button
                                            primary
                                            className="theme"
                                            loading={saving}
                                            onClick={save}
                                        >
                                            Save
                                        </Button>
                                    </Segment>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                {body()}
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
